import React, { useState } from "react";
import Button from "@mui/material/Button";
import CustomizationTabs from "./CustomizationTabs.js";
import SubmitModal from "../submitModal/submitModal";
import dayjs from "dayjs";
import HeaderReviewModal from "../headerReviewModal/HeaderReviewModal.js";
import {
  saveDraftAnalysis,
  saveSubmitWorkup,
  submitWorkup
} from "../../../Redux/Actions/workups.js";
import { ReactComponent as Vector } from "../../../assets/images/Vector.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PositionedSnackbar from "../../positionedSnackbar/PositionedSnackbar.js";
import { CrossMask, GreenTick } from "../../../utils/icons.js";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function AntigramHeader({
  apiAllData,
  rmToggle,
  handleRMToggle,
  classificRule,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  // const{modalOpen}=location.state;
  // console.log(location)
  const { panelId } = useParams();
  const antigramHederData = apiAllData?.workup;
  // console.log(apiAllData)
  const { name, status, modified, patient, antibody_history, diagnosis } =
    antigramHederData;
  const [open, setOpen] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setReviewOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleReviewClickOpen = () => {
    setReviewOpen(true);
  };
  const handleReviewClose = () => {
    setReviewOpen(false);
  };
  // console.log(apiAllData);
  const handleSaveSubmitWorkup = async () => {
    const apiObj = {
      // name: name,
      // "status": "not_completed",
      // interpretations: classificRule,
      interpretations: {
        ruled_in: ["D", "C"],
        ruled_out: ["E", "c", "e"],
        unknown: [],
      },
      panels: [
        {
          id: apiAllData?.panels[0]?.panel?.id,
          "user_mask": [
            {
                "cell_number": "3", 
                "antigen": "E", 
                "symbol": "X"
            }
        ]
          // user_mask: apiAllData?.panels[0]?.mask,
        },
      ],
    };
    const apiObj2 = {
      interpretations: {
        ruled_in: ["D", "C"],
        ruled_out: ["E", "c", "e"],
        unknown: [],
      },
      panels: [
        {
          id: apiAllData?.panels[0]?.panel?.id,
          "user_mask": [
            {
                "cell_number": "3", 
                "antigen": "E", 
                "symbol": "X"
            }
        ]
          // user_mask: apiAllData?.panels[0]?.mask,
        },
      ],
    };
    const res = await saveSubmitWorkup(panelId, apiObj);
    if (res?.status == 200) {
      // navigate("/workup-home");
    }
  };
  const handleSaveDraftAnalysis = async () => {
    const apiObj = {
      name: name,
      // "status": "not_completed",
      interpretations: classificRule,
    };
    const res = await saveDraftAnalysis(panelId, apiObj);
    if (res?.status == 200) {
      navigate("/workup-home");
    }
      // const rule_data = {
    //   ...classificRule.rule_outs,
    //   ...classificRule.rule_outs_slash,
    // };
    // const classificRuleData = [
    //   { rule_ins: classificRule?.rule_ins },
    //   { rule_outs: rule_data },
    // ];
  };
  const handleSubmitWorkup = async () => {
    const apiObj = {
      name: name,
      "status": "completed",
      // interpretations: selectedAnalysisData,
    };
    const res = await submitWorkup(panelId, apiObj);
    if (res?.status == 200) {
      // setOpen(false);
      navigate("/workup-home");
    }
  };
  const selectedAnalysis = localStorage.getItem("selectedAnalysis");
  const selectedAnalysisData = JSON.parse(selectedAnalysis);
 

  const handleEditWorkupPanel = () => {
    navigate(`/single-workup/${panelId}`, {
      state: { testResultData: false, editWorkupData: true },
    });
  };

  const [successOpen, setSuccessOpen] = useState(false);
  const handleSuccessClose = () => {
    localStorage.removeItem("createStatus");
    window.location.reload();
    setSuccessOpen(false);
  };

  return (
    <div className="antigram-panel-header-wrapper">
      {localStorage.getItem("createStatus") && (
        <div className="workup-created-message">
          <GreenTick width={25} height={25} />
          Test Workup created: A new workup has been created with the selected
          results.
          <div style={{ cursor: "pointer" }} onClick={handleSuccessClose}>
            <CrossMask width={15} height={15} />
          </div>
        </div>
      )}
      <SubmitModal
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleReviewClickOpen={handleReviewClickOpen}
        handleSubmitWorkupData={handleSubmitWorkup}
      />
      <HeaderReviewModal
        handleReviewClickOpen={handleReviewClickOpen}
        handleReviewClose={handleReviewClose}
        reviewOpen={reviewOpen}
        handleClickOpen={handleClickOpen}
        classificRule={classificRule}
      />
      <div className="antigram-panel-header">
        <div className="antigram-title-row">
          <h1 className="workuptitle">{name && name.length >10 ? name.substring(0, 10)+"..." : name}</h1>
          {status == "COMPLETED" ? (
            ""
          ) : (
            <span
              className="edit-vector-icon"
              onClick={status == "COMPLETED" ? null : handleEditWorkupPanel}
            >
              <Vector />
            </span>
          )}
          <span className={status == "COMPLETED" ? `completed-status ${status}` : `status ${status}`} >{status}</span>
          <span className="last-modified">
          {status == "COMPLETED" ? "Manual analysis submitted" : 'Last modified'}{" "}
            {modified?.date_time === undefined
              ? "-"
              : dayjs(modified?.date_time).local().format("MM/DD/YYYY HH:MM")}
          </span>
          <Button className="wk-notes-btn" variant="outlined" color="error">
            Workup Notes <span className="notes-count">{0}</span>
          </Button>
          <Button
            // onClick={handleSaveSubmitWorkup}
            onClick={handleSaveDraftAnalysis}
            className="wk-save-btn"
            variant="outlined"
            color="error"
            disabled={status == "COMPLETED"}
          >
            Save changes
          </Button>
          <Button
            onClick={handleReviewClickOpen}
            className="wk-submit-btn"
            variant="contained"
            color="error"
            disabled={status == "COMPLETED"}
          >
            Submit
          </Button>
        </div>
        <div className="antigram-patient-details-row">
          <div className="patient-data-group">
            <span className="label">Patient Name</span>
            <span className="value">
              {patient?.first_name} {patient?.last_name}
            </span>
          </div>
          <div className="patient-data-group">
            <span className="label">Patient ID</span>
            <span className="value">{patient?.id}</span>
          </div>
          <div className="patient-data-group">
            <span className="label">History of antibodies</span>
            <span className="value">{antibody_history}</span>
          </div>
          <div className="patient-data-group">
            <span className="label">Rh factor</span>
            <span className="value">{patient?.abo_rh}</span>
          </div>
          <div className="patient-data-group">
            <span className="label">Diagnosis</span>
            <span className="value">{diagnosis}</span>
          </div>
          <Button className="wk-info-btn" variant="outlined" color="error">
            Patient Information
          </Button>
        </div>
        <div className="antigram-customization-row">
          <div className="antigram-customization-toggle-wrapper">
            <CustomizationTabs
              rmToggle={rmToggle}
              handleRMToggle={handleRMToggle}
              apiData={apiAllData}
              classificRule={classificRule}
            ></CustomizationTabs>
          </div>
        </div>
      </div>
    </div>
  );
}
