export const userDetails = [
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T10:10:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T14:04:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T18:18:12Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T18:09:21Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T20:23:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T23:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "-",
  "result_date": "2022-06-23T22:04:20Z",
  "laboratory": "Laboratory 4",
  "panel_type": "A",
  "workup_name": "-",
  "status": "new",
  "sample_id": "7417016049876",
  "patient_id": "-",
  "lot_id": "664500412"
},
{
  "patient_name": "-",
  "result_date": "2022-06-23T09:23:22Z",
  "laboratory": "Laboratory 4",
  "panel_type": "A",
  "workup_name": "-",
  "status": "new",
  "sample_id": "7417016049876",
  "patient_id": "-",
  "lot_id": "664500412"
},
{
  "patient_name": "-",
  "result_date": "2021-09-18T01:23:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "A",
  "workup_name": "-",
  "status": "new",
  "sample_id": "7613817474567",
  "patient_id": "-",
  "lot_id": "006815096"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T06:20:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T03:20:02Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T01:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T10:10:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T14:04:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T18:18:12Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T18:09:21Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T20:23:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T23:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "-",
  "result_date": "2021-09-18T01:23:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "A",
  "workup_name": "-",
  "status": "new",
  "sample_id": "7613817474567",
  "patient_id": "-",
  "lot_id": "006815096"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T06:20:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T03:20:02Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T01:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T10:10:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T14:04:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T18:18:12Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T18:09:21Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T20:23:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T23:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "-",
  "result_date": "2021-09-18T01:23:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "A",
  "workup_name": "-",
  "status": "new",
  "sample_id": "7613817474567",
  "patient_id": "-",
  "lot_id": "006815096"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T06:20:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T03:20:02Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T01:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T10:10:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T14:04:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T18:18:12Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T18:09:21Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T20:23:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T23:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "-",
  "result_date": "2021-09-18T01:23:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "A",
  "workup_name": "-",
  "status": "new",
  "sample_id": "7613817474567",
  "patient_id": "-",
  "lot_id": "006815096"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T06:20:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T03:20:02Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T01:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},

{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T10:10:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T14:04:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T18:18:12Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T18:09:21Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T20:23:22Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T23:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
{
  "patient_name": "-",
  "result_date": "2021-09-18T01:23:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "A",
  "workup_name": "-",
  "status": "new",
  "sample_id": "7613817474567",
  "patient_id": "-",
  "lot_id": "006815096"
},
{
  "patient_name": "Neil,Longridge",
  "result_date": "2022-03-17T06:20:22Z",
  "laboratory": "Laboratory 1",
  "panel_type": "C",
  "workup_name": "Gripe",
  "status": "in progress",
  "sample_id": "6117293097564",
  "patient_id": "3506239430",
  "lot_id": "077043051"
},
{
  "patient_name": "Charlotte,Haig",
  "result_date": "2022-04-22T03:20:02Z",
  "laboratory": "Laboratory 2",
  "panel_type": "B",
  "workup_name": "Virus 1B",
  "status": "in review",
  "sample_id": "9295454960987",
  "patient_id": "0484948938",
  "lot_id": "799146622"
},
{
  "patient_name": "Eloisa,Marxsen",
  "result_date": "2021-12-13T01:23:22Z",
  "laboratory": "Laboratory 3",
  "panel_type": "Screen",
  "workup_name": "Virus 1H",
  "status": "completed",
  "sample_id": "4076075722456",
  "patient_id": "7717946742",
  "lot_id": "829527440"
},
]




