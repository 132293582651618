import Api from "../../utils/apiConfig";

export const getWorkups = async () => { 
    const response = await Api.get(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/%7BorgScope%7D/workups",
      true
    );
    if (response) {
      return response?.data;
    }
  
};

export const getWorkupsDetails = async (workupId) => {
  try {
    const response = await Api.get(
      `https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/%7BorgScope%7D/workups/${workupId}/details`,
      true
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    // console.log("err", err);
  }
};

export const geSingletWorkups = async (id) => {
  try {
    const response = await Api.get(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/{orgScope}/workups/"+id,
      true
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    // console.log("err", err);
  }
};

export const getFiltersFromApi = async () => {
  try {
    const response = await Api.get(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/me",
      {}
    );
    if (response) {
      return response?.data?.workups_filter;
    }
  } catch (err) {
  //   console.log("err", err);
  }
};

export const saveFiltersToApi = (filters) => {
  try {
      
    const response = Api.post(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/me/kvs/workups_filter",
      {value:JSON.stringify(filters)}
    );
    if (response) {
      
      return response?.data;
    }
  } catch (err) {
  //   console.log("err", err);
  }
}
export const updtSingletWorkups = async (id,data) => {
    
  try {
    const response = await Api.post(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/{orgScope}/workups/"+id,
      data
    );
    if (response) {
      console.log(response,"responseupdt")
      return response;
    }
  } catch (err) {
    // console.log("err", err);
  }
};

export const saveDraftAnalysis = async (id,data) => {
  try {
    const response = await Api.post(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/{orgScope}/workups/"+id,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
   console.log("err", err);
  }
}
export const submitWorkup = async (id,data) => {
  try {
    const response = await Api.post(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/{orgScope}/workups/"+id,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
   console.log("err", err);
  }
}
export const saveSubmitWorkup = async (id,data) => {
  try {
    const response = await Api.post(
      `https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/{orgScope}/workups/${id}/analysis`,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
   console.log("err", err);
  }
}

