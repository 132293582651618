import dayjs from "dayjs";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const objectSupport = require("dayjs/plugin/objectSupport");
dayjs.extend(objectSupport);

export const getDatePartDayJs = (jsDate) => {
  const dayjsDate = dayjs(jsDate);
  return dayjs({ year: dayjsDate.$y, month: dayjsDate.$M, day: dayjsDate.$D });
};

export const getTableDateTimeFormat=(date)=>{
  return dayjs(date).local().format("MM/DD/YYYY - HH:MM");
}
