import React, { useEffect } from "react";
import { AppBar, Badge, Toolbar, IconButton, Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircle from "@mui/icons-material/AccountCircle";
import "./index.scss";
//Header Component
const Header = (props) => {
  const { userData } = props;

  return (
    <AppBar position="static">
      <Toolbar>
        {userData?.app_name}
        <Typography component="div" sx={{ flexGrow: 1 }}></Typography>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          {userData &&
            userData?.notification &&
            userData?.notification?.map((item, idx) => {
              return (
                <Badge key={idx} badgeContent={item?.count} color="error">
                  <NotificationsNoneIcon className="notification-icon" />
                </Badge>
              );
            })}
        </IconButton>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
          // aria-controls={menuId}
          // onClick={handleProfileMenuOpen}
        >
          <AccountCircle className="user-avatar" />
        </IconButton>
        <Typography variant="h7" component="div" className="user-details">
          <span className="user-name">{userData.user_name}</span>
          <span className="user-destination">{userData.account_name}</span>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
