import React, { useState, useEffect, useRef } from "react";
import PatientPagination from "./patientPagination";
import PatientTable from "./patientTable";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useLocation } from "react-router-dom";

const statusSort = {
  NEW: 1,
  INPROGRESS: 2,
  INREVIEW: 3,
  COMPLETED: 4,
};
const PatientHome = (props) => {
  const location = useLocation();
  // console.log("PatientHome",props,"location====",location,location.state)
  let addPatientDetails;
  if (!props.addPatientDetails) {
    const location = useLocation();
    addPatientDetails = location.state.addPatientDetails;
  }
  else if(location.state == null){
    addPatientDetails=false
  }
  else{
    addPatientDetails=false
  }
  // console.log(location.state,"pateintdata",addPatientDetails)

  const dataRef = useRef({ allData: [] });
  const [posts, setPosts] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [sortColumn_a, setSortColumnA] = useState({ col: "" });
  const [sortColumn_d, setSortColumnD] = useState({ col: "" });

  useEffect(() => {
    getActualData();
  }, []);

  const getPatientData = async () => {
    const headers = {
      authorizationToken: Auth.currentSession().then(
        (res) => res.idToken.jwtToken
      ),
    };
    try {
      const response = await axios.get(
        "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/{orgScope}/patients",
        { headers }
      );
      if (response) {
        return response?.data;
      }
    } catch (err) {
      // console.log("err", err);
    }
  };

  const getActualData = async () => {
    const data = await getPatientData();
    updateAllPosts(data.patients);
  };

  const updateAllPosts = (data) => {
    dataRef.current.allData = data;
    setPosts(data);
  };
  const getSortUpFunc = (col) => {
    const sortFunc =
      col === "status"
        ? (a, b) =>
            statusSort[a[col].replace(" ", "")] <
            statusSort[b[col].replace(" ", "")]
              ? -1
              : 1
        : (a, b) => (a[col] < b[col] ? -1 : 1);

    return sortFunc;
  };

  const getSortDownFunc = (col) => {
    const sortFunc =
      col === "status"
        ? (a, b) =>
            statusSort[a[col].replace(" ", "")] >
            statusSort[b[col].replace(" ", "")]
              ? -1
              : 1
        : (a, b) => (a[col] > b[col] ? -1 : 1);

    return sortFunc;
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // Get current posts //
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // sorting for PatientTable //

  // Function for UpArrow //
  const sortingUp = (col) => {
    setSortColumnA({ [col]: "red" });
    setSortColumnD({ [col]: "" });

    const sortFunc = getSortUpFunc(col);
    setPosts([...posts].sort(sortFunc));
  };

  // Function for DownArrow //

  const sortingDown = (col) => {
    setSortColumnD({ [col]: "red" });
    setSortColumnA({ [col]: "" });
    const sortFunc = getSortDownFunc(col);

    setPosts([...posts].sort(sortFunc));
  };

  //  On Page Change //
  const onChange = (event, nextPage) => {
    setCurrentPage(nextPage);
  };

  return (
    <div>
      <PatientTable
        data={posts}
        addPatientDetails={addPatientDetails}
        sortingUp={sortingUp}
        sortingDown={sortingDown}
        sortColumn_a={sortColumn_a}
        sortColumn_d={sortColumn_d}
      />
      <PatientPagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        onChange={onChange}
        lastIndex={indexOfLastPost}
        startIndex={indexOfFirstPost}
        currentPosts={currentPosts}
      />
    </div>
  );
};

export default PatientHome;
