import React from "react";
import "./index.scss";
import Constants from "../../../../languages/en.json";
import FormattedLabel from "../../../formattedLabel/FormattedLabel";

const ClassificationRow = ({ ruledClassificObj }) => {
  const classificationRowData = () => {
    const colOrder = Constants.en.antigramTable.classificationRow;
    const classificationCells = [];

    let ruleOuts;
    let ruleOutsSlash;
    let ruleIn;

    ruleOuts = ruledClassificObj?.rule_outs;
    ruleOutsSlash = ruledClassificObj?.rule_outs_slash;
    ruleIn = ruledClassificObj?.rule_ins;

    let ruleClass;
    //let counts = 0;
    let couterLabel;

    for (var j = 0; j < colOrder.length; j++) {

      if (
        ruleOuts &&
        ruleOuts.length > 0 &&
        ruleOuts.indexOf(colOrder[j]) > -1
      ) {
        ruleClass = "classific-ruled-out-bg";
        // counts = counts++;
        // couterLabel = <span className='count'>{counts}+</span>;
      } else if (
        ruleOutsSlash &&
        ruleOutsSlash.length > 0 &&
        ruleOutsSlash.indexOf(colOrder[j]) > -1
      ) {
        ruleClass = "classific-ruled-out-bg";
      } else if (
        ruleIn &&
        ruleIn.length > 0 &&
        ruleIn.indexOf(colOrder[j]) > -1
      ) {
        ruleClass = "classific-ruled-in-bg";
      } else {
        ruleClass = "";
        couterLabel = "";
      }
      classificationCells.push(
        <div key={j} className="classfic-wrapper">
          <span className={`classfic-cell cl-${j + 1} ${colOrder[j]} ${ruleClass}`}>
            <span>
              <FormattedLabel j={colOrder[j]}></FormattedLabel>
            </span>
          </span>
          {couterLabel}
        </div>
      );
    }
    return classificationCells;
  };

  //   return classificationRowData()
  return (
    <div className="classification-tab">
      <div className="label-row">
        <div className="label ruled-in">
          <span className="dot"></span>Ruled in
        </div>
        <div className="label ruled-out">
          <span className="dot"></span>Ruled out
        </div>
        <div className="label undetermined">
          <span className="dot"></span>Undetermined
        </div>
      </div>
      <div className="values-row">
        {classificationRowData()}
      </div>
    </div>
  );
};
export default ClassificationRow;
