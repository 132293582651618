import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ClassificationRow from "../antigramHeader/ClassificationRow/ClassificationRow";
// import "../antigramHeader/index.scss";

const submitModalSelector = (props) => {
  const [value, setValue] = useState("");

  const classificationOrigData = JSON.parse(
    localStorage.getItem("localApiAllDataOriginal")
  );
  const classificationOrigParsedData =
    classificationOrigData?.classifications?.algorithm_solution;

  const classificationModifData = JSON.parse(
    localStorage.getItem("localApiAllDataModified")
  );
  const classificationModifParsedData =
    classificationModifData?.classifications?.algorithm_solution;

  const handleChange = (event, id) => {
    setValue(event.target.value);
    if (event.target.value == "Recommended") {
      props.handleRecColor("#E9F0F3")
      localStorage.setItem(
        "selectedAnalysisKey",
       event.target.value
      );
      localStorage.setItem(
        "selectedAnalysis",
        JSON.stringify(classificationOrigParsedData)
      );
    } else if (event.target.value == "Manual") {
      props.handleManualColor("#E9F0F3")
      localStorage.setItem(
        "selectedAnalysisKey",
        event.target.value
      );
      localStorage.setItem(
        "selectedAnalysis",
        JSON.stringify(classificationModifParsedData)
      );
    }
    props.testColor("#E9F0F3")
    // setValue(id);
  };

  return (
      <FormControl>
        {/* <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel> */}
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <div className="recommended-container">
          <FormControlLabel
            value="Recommended"
            control={
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "#CF2F44",
                  },
                }}
              />
            }
            label="RECOMMENDED"
          />
          <ClassificationRow ruledClassificObj={classificationOrigParsedData} />
          </div>
          <div className="manual-container">
          <FormControlLabel
            value="Manual"
            control={
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "#CF2F44",
                  },
                }}
              />
            }
            label="MANUAL"
          />
          <ClassificationRow
            ruledClassificObj={classificationModifParsedData}
          />
          </div>
        </RadioGroup>
      </FormControl>
  );
};
export default submitModalSelector;
