import React from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Switch from '@mui/material/Switch';
import PositionedSnackbar from '../../../SnackbarPopup/SnackbarPopup'
import WarningIcon from '@mui/icons-material/Warning';
import FormattedLabel from "../../../formattedLabel/FormattedLabel";
import Constants from "../../../../languages/en.json";
import "./index.scss";

const colOrder = Constants.en.antigramTable.classificationRow;
const antTypConsts = Constants.en.antigramTable.workupHeader.antigenTyping;

const warningMessage = () => {
  return(<React.Fragment><WarningIcon className='warning-icon' style={{color: "#F2A900"}} /><span className='alert-note'><strong>Workup alert:</strong> Recommended classifications were updated.</span></React.Fragment>);
};

const AntigenTyping = () => {

  const antigenTypingRowData = () => {
    const antigenTypingCells = [];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentCell, setCurrentCell] = React.useState();
    const [antTypingCard, setAntTypingCard] = React.useState({});
    const [antTypingMF, setAntTypingMF] = React.useState({});
    const open = Boolean(anchorEl);
    let cellDisabled;
    let cellDisabledClass;

    const updateTypingRules = (val, event) =>{
      setAnchorEl(event.currentTarget);
      setCurrentCell(val);
    }

    const handleClose = () => {
      setAnchorEl(null);
    };
    
    /* Dropdown slect handler */
    const updateTypingCards = (typingVal) =>{
      if (antTypingCard != null && currentCell in antTypingCard && antTypingCard[currentCell] != undefined && antTypingCard[currentCell] != "" && antTypingCard[currentCell] == typingVal) {
        setAntTypingCard({ ...antTypingCard, [currentCell]: "" });
      } else {
        setAntTypingCard({ ...antTypingCard, [currentCell]: typingVal });
      }
      handleClose();
    }
    
    /* Dropdown switch change handler */
    const updateMfTags = () =>{    
      if (antTypingMF != null && currentCell in antTypingMF && antTypingMF[currentCell] != undefined && antTypingMF[currentCell] != "") {
        setAntTypingMF({ ...antTypingMF, [currentCell]: "" });
      } else {
        setAntTypingMF({ ...antTypingMF, [currentCell]: "MF" });
      }
      handleClose();
    }

    antigenTypingCells.push(
      <Menu
        id="fade-menu"
        className="antigen-type-drodown-modal-main"
        MenuListProps={{ 'aria-labelledby': 'fade-button'}}
        key={new Date().getTime()}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={()=>updateTypingCards("0")}>0</MenuItem>
        <MenuItem onClick={()=>updateTypingCards("1+")}>1+</MenuItem>
        <MenuItem onClick={()=>updateTypingCards("2+")}>2+</MenuItem>
        <MenuItem onClick={()=>updateTypingCards("3+")}>3+</MenuItem>
        <MenuItem onClick={()=>updateTypingCards("4+")}>4+</MenuItem>
        <MenuItem onClick={()=>updateTypingCards("NT")}>{antTypConsts.NT}</MenuItem>
        <MenuItem className="switch-btn">
          <Switch
            checked={(antTypingMF[currentCell]=="MF")?true:false}
            onChange={()=>{updateMfTags()}}
            inputProps={{ 'aria-label': 'controlled' }}
            size="small"/><span>{antTypConsts.MF}</span>
        </MenuItem>
      </Menu>
    );

    /* Loop antigen cells row */
    for (var j = 0; j < colOrder.length; j++) {
      j==5 || j==12 || j==14 ? cellDisabled=true : cellDisabled=false;
      cellDisabled==true ? cellDisabledClass="disabled" : cellDisabledClass="";
      let addMfClass = antTypingMF[colOrder[j]]=="MF" ? "mf-cell" : "";

      antigenTypingCells.push(
        <div key={j} className={`antigen-typing-cell-outer ${cellDisabledClass}`}>
          <span className={`antigen-typing-cell ${addMfClass} cl-${j + 1} ${colOrder[j]}`}>
            <FormattedLabel j={colOrder[j]}></FormattedLabel>
            {antTypingMF[colOrder[j]]=="MF" && <span className="mf-tag">{antTypConsts.MF}</span>}
          </span>
          <span className="antigen-typing-card"
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            //onClick={(event) => updateTypingRules("colOrder[j]", handleClick(event, colOrder[j]))}
            onClick={cellDisabled==true ? null : updateTypingRules.bind(event, colOrder[j])}
            >{antTypingCard && antTypingCard?.[colOrder[j]]}</span>
        </div>
      );
    }
    return antigenTypingCells;
  };

  return (
    <div className="antigen-typing-wrapper">
      {antigenTypingRowData()}
      {true && <PositionedSnackbar fwdStyles={{ color:'#34383C', backgroundColor:'#FFF2D0', borderColor:'#F2A900' }}>{warningMessage()}</PositionedSnackbar>}
    </div>
    
  );
};

export default AntigenTyping;