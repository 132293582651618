import Api from "../../utils/apiConfig";

export const createWorkups = async (data) => {
  try {
    console.log("create workup", data);
    const response = await Api.post(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/org/workups",data
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    // console.log("err", err);
  }
};

