import React, { useState } from "react";
import AlertDialogModal from "../../components/alertDialogModal/AlertDialogModal";
import "./index.scss";
const RemoveTestModal = (props) => {
  const Styles = {
    "& .MuiDialog-container": {
      padding: "60px",
      "& .MuiPaper-root": {
        width: "600px",
        padding: "8px",
        "& .MuiTypography-root": {
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#34383C",
          "& .section-line": {
            position: "absolute",
            height: "10px",
            left: "0px",
            right: "0px",
            top: "0px",
            background: "#E7E7ED",
          },
          "& .remove-modal-sub-content": {
            fontWeight: "400",
            fontSize: "14px",
            color: "#34383C",
            lineHeight: "45px",
            // borderTop:"1px solid #E7E7ED",
          },
        },
        "& .MuiButtonBase-root.alert-cancel": {
          color: "#34383C",
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "18px",
          border: "1px solid #34383C",
          padding: "8px 22px",
          webkitTapHighlightColor: "none",
        },
        "& .MuiButtonBase-root.alert-submit": {
          padding: "9px 22px",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "21px",
          webkitTapHighlightColor: "none",
        },
      },
    },
  };
  const contentHere = () => {
    return (
      <>
        <div className="remove-modal-sub-content">
          Are you sure you want to remove this test result(s) from the workup?
          <br />
          Removing test results will DELETE any manual edits on the result.
        </div>
      </>
    );
  };
  return (
    <AlertDialogModal
      sx={Styles}
      dialogButtonName="test"
      className="remove-test-modal"
      dialogTitle={`Remove test result`}
      dialogContent={contentHere()}
      btnYesText="Remove"
      btnNoText="Cancel"
      open={props?.open}
      handleClickOpen={props?.handleClickOpen}
      handleClose={props?.handleClose}
      handleProceed={props?.handleProceed}
    />
  );
};
export default RemoveTestModal;
