import React, { useState, useEffect, useRef, useContext } from "react";
import { getWorkups } from "../../../Redux/Actions/workups.js";
import { getWorkupsDetails } from "../../../Redux/Actions/workups.js";
import WorkupTable from "./WorkupTable";
import WorkupPagination from "./workupPagination/WorkupPagination.js";
import Constants from "../../../languages/en.json";
import { nameFormatter } from "../../../utils/tableValueFormatter";
import {
  getFiltersFromApi,
  saveFiltersToApi,
  geSingletWorkups,
  updtSingletWorkups,
} from "../../../Redux/Actions/workups";

import { getDatePartDayJs, getTableDateTimeFormat } from "../../../utils/date";
import { getMatchingField as getMatchingFieldUtil } from "../../../utils/search";
import {
  caseInsensitiveTextSortAsc,
  caseInsensitiveTextSortDesc,
} from "../../../utils/column-sort.js";
import { SpinnerContext } from "../../../context/spinner-context.js";
import { WorkupTestDetails } from "./workupTestTable/WorkupTestDetails.js";
import { useLocation, useNavigate } from "react-router-dom";
import { createWorkups } from "../../../Redux/Actions/create-workups";
import { Button, Link, Stack } from "@mui/material";
import { communicationStorage } from "../../../services/communicationService";

const statusSort = Constants.en.testTable.statusSorting;

// Checks if there is any field where the searchtext matches.
const matcher = {
  result_dt: (value, searchText) => {
    return getTableDateTimeFormat(value).includes(searchText);
  },
};

const getMatchingField = (row, searchText) => {
  return getMatchingFieldUtil(row, searchText, matcher);
};

const getDataSatisfyingSearch = (data, searchText) => {
  if (data.length === 0 || searchText == "") {
    return data;
  }

  // console.log("getDataSatisfyingSearch", data);

  const lowerCaseSearchText = searchText.toLowerCase();
  const filteredData = data.filter((p) => {
    const matchingField = getMatchingField(p, lowerCaseSearchText);
    return matchingField ?? false;
  });

  return filteredData;
};

const getFilteredData = (data, filters) => {
  let filteredData = data;

  if (filters.statuses && filters.statuses.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.statuses.includes(p.status)
    );
  }

  if (filters.panelTypes && filters.panelTypes.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.panelTypes.includes(p.panel_type)
    );
  }

  if (filters.lastUpdatedBy && filters.lastUpdatedBy.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.lastUpdatedBy.includes(p.last_updated_by)
    );
  }

  if (filters.startDate) {
    const startDateDayJS = getDatePartDayJs(filters.startDate);

    filteredData = filteredData.filter(
      (p) =>
        p.result_dt && getDatePartDayJs(new Date(p.result_dt)) >= startDateDayJS
    );
  }

  if (filters.endDate) {
    const endDateDayJS = getDatePartDayJs(filters.endDate);
    filteredData = filteredData.filter(
      (p) =>
        p.result_dt && getDatePartDayJs(new Date(p.result_dt)) <= endDateDayJS
    );
  }

  return filteredData;
};

const sortAscFuncs = {
  status: (a, b) =>
    statusSort[a["status"].replace(" ", "")] <
    statusSort[b["status"].replace(" ", "")]
      ? -1
      : 1,
  regular: (col) => (a, b) => caseInsensitiveTextSortAsc(a[col], b[col]),
  date: (col) => (a, b) => a[col] < b[col] ? -1 : 1,
};

const sortDescFuncs = {
  status: (a, b) => sortAscFuncs.status(a, b) * -1,
  regular: (col) => (a, b) => caseInsensitiveTextSortDesc(a[col], b[col]),
  date: (col) => (a, b) => a[col] < b[col] ? 1 : -1,
};

const getFlattenedPosts = (posts) => {
  return posts.map((p) => {
    const flattenedData = {
      id: p.id,
      workup_name: p.name,
      status: p.status,
      submitted_date: p.submitted?.date_time,
    };
    if (p.patient) {
      flattenedData.patient_id = p.patient.id;
      flattenedData.patient_name = nameFormatter(
        p.patient.last_name,
        p.patient.first_name
      );
      flattenedData.last_updated_by = nameFormatter(
        p.patient.last_name,
        p.patient.first_name
      );
    }
    if (p.modified) {
      flattenedData.last_updated_by = nameFormatter(
        p.modified.first_name,
        p.modified.last_name
      );
      flattenedData.result_dt = p.modified.date_time;
    }
    return flattenedData;
  });
};

const WorkupHomePage = () => {
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAddToWorkupFlow, selectedTestResult } = location.state;
  const postsRef = useRef({ allPosts: [] });
  const [apiError, setApiError] = useState();
  const [posts, setPosts] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [sortParam, setSortParam] = useState({});
  const [searchText, setSearchText] = useState("");
  const [workupDetailsById, setWorkupDetailsById] = useState({});
  const [filterValues, setFilterValues] = useState({ lastUpdatedBy: [] }); // remove laboratories later.Currently filter is comomon so it fails
  const [selectedWorkups, setSelectedWorkups] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const updateFromApiRef = useRef();
  const getWorkupsCall = async () => {
    try {
      const res = await getWorkups();
      // console.log("workup", res);
      if (res) {
        updateAllData(res?.workups);
      }
      setApiError(null);
    } catch (e) {
      setApiError({ message: "Error occured" });
    }
  };

  const getPostsToShow = (searchText, filters) => {
    const flattenedPosts = getFlattenedPosts(postsRef.current.allPosts);
    const lastUpdatedByList = [
      ...new Set(
        flattenedPosts
          .map((item) => item.last_updated_by)
          .filter((i) => i != null)
      ),
    ];
    // console.log("flattended", flattenedPosts, lastUpdatedByList);
    setFilterValues({ lastUpdatedBy: lastUpdatedByList });
    let postsToShow = getDataSatisfyingSearch(flattenedPosts, searchText);
    postsToShow = getFilteredData(postsToShow, filters);

    return postsToShow;
  };

  updateFromApiRef.current = getWorkupsCall;

  useEffect(() => {
    changeSpinnerStatus(true);

    initialize();

    // setTimeout(()=>initialize(), 1000);

    const timer = setInterval(() => updateFromApiRef.current(), 30000);
    return () => clearInterval(timer);
  }, []);

  const initialize = async () => {
    await getWorkupsCall();

    var filters = await getFiltersFromApi();

    if (filters) {
      setFilters(JSON.parse(filters) || {});
    }

    changeSpinnerStatus(false);
  };

  const updateAllData = (data) => {
    postsRef.current.allPosts = data;
    updatePostsToShow();
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // Get current posts //
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // sorting for TestTable //

  const handleSortChanged = (field, sortDirection) => {
    setSortParam({ [field]: sortDirection });
  };

  //  On Page Change //
  const onChange = (event, nextPage) => {
    setCurrentPage(nextPage);
  };

  const updateFilters = (filters) => {
    // console.log("filters", filters);
    setFilters(filters);
    setCurrentPage(1);
    saveFiltersToApi(filters);
  };

  useEffect(() => {
    updatePostsToShow();
  }, [searchText, filters, sortParam]);

  const updatePostsToShow = () => {
    const filteredPosts = getPostsToShow(searchText, filters);
    const sortedPosts = sortDataList(filteredPosts, sortParam);
    setPosts(sortedPosts);
  };

  const sortDataList = (data, sortParam) => {
    let sortFunc;

    const keys = Object.keys(sortParam);
    // no sort param available
    if (keys.length == 0) {
      sortFunc = sortDescFuncs["date"]("result_dt");
    } else {
      const field = keys[0];

      // Ascending
      if (sortParam[field] == 0) {
        if (sortAscFuncs[field]) {
          sortFunc = sortAscFuncs[field];
        } else {
          sortFunc = sortAscFuncs["regular"](field);
        }
      }

      // Descending
      else if (sortParam[field] == 1) {
        if (sortDescFuncs[field]) {
          sortFunc = sortDescFuncs[field];
        } else {
          sortFunc = sortDescFuncs["regular"](field);
        }
      }
    }

    return data.sort(sortFunc);
  };

  const handleSearchText = (searchText) => {
    setSearchText(searchText);
    setCurrentPage(1);
  };

  const fetchWorkupDetail = async ({ id }) => {
    const details = await getWorkupsDetails(id);
    const newWorkupDetails = { ...workupDetailsById, [id]: details };
    setWorkupDetailsById(newWorkupDetails);
  };

  const handleRowExpanded = (workup, isExpanded) => {
    console.log();
    if (isExpanded) {
      setExpandedRows([...expandedRows, workup.id]);
      fetchWorkupDetail(workup);
    } else {
      setExpandedRows(expandedRows.filter((id) => id != workup.id));
    }
  };

  // const testResult = isNavigatingFromTestResult
  //   ? JSON.parse(localStorage.getItem("SelectedTestResult"))
  //   : undefined;
  // console.log("selected test result", testResult);

  const handleWorkupSelected = (workup, isSelected) => {
    // to be done
    console.log("workup selected", workup, isSelected);

    if (isSelected) {
      setSelectedWorkups([...selectedWorkups, workup.id]);
    } else {
      setSelectedWorkups(selectedWorkups.filter((id) => id != workup.id));
    }
  };

  const handleAddToWorkup = async () => {
    console.log(
      "handle add to workup called",
      selectedWorkups,
      selectedTestResult,
      currentPosts
    );

    const backgroundTasks = [];

    selectedWorkups.forEach(async (workupId) => {
      const workup = currentPosts.find((w) => workupId == w.id);

      if (workup) {
        const apiObj = {
          name: workup?.workup_name,
          patient_guid: selectedTestResult?.patient_id,
          reference_key_map: [
            {
              antigram_reference_key:
                selectedTestResult?.antigram_reference_key,
              test_result_reference_key: selectedTestResult?.sample_id,
            },
          ],
        };

        const task = createWorkups(apiObj);
        backgroundTasks.push(task);
        //console.log("saved workups")
      }
    });

    await Promise.all(backgroundTasks);
    communicationStorage.isAddToWorkupFlowSuccess=true;
    navigate(`/`);
  };

  const handleNewWorkup = () => {
    navigate(`/single-workup`, {
      state: {
        isAddToWorkupFlow: true,
        testResultData: [selectedTestResult],
        addPatientData: [],
      },
    });
  };

  const handleCancelAddToWorkup = () => {
    // Should do cleanup here - Need to do
    navigate(`/`);
  };

  return (
    <div>
      {isAddToWorkupFlow && (
        <WorkupTestDetails testResult={selectedTestResult}></WorkupTestDetails>
      )}
      {!isAddToWorkupFlow && <h2 className="page-title">Workups</h2>}
      <WorkupTable
        posts={currentPosts}
        setPosts={setPosts}
        filters={filters}
        filterValues={filterValues}
        updateFilters={updateFilters}
        onSearchTextChange={handleSearchText}
        searchText={searchText}
        onSortChanged={handleSortChanged}
        sortParam={sortParam}
        workupDetails={workupDetailsById}
        serverError={apiError}
        isAddWorkup={isAddToWorkupFlow}
        selectedWorkups={selectedWorkups}
        onWorkupSelected={handleWorkupSelected}
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        onChange={onChange}
        lastIndex={indexOfLastPost}
        startIndex={indexOfFirstPost}
        currentPosts={currentPosts}
        expandedRows={expandedRows}
        onRowExpanded={handleRowExpanded}
        onNewWorkup={handleNewWorkup}
      />
      {isAddToWorkupFlow && (
        <AddToWorkUpActions
          onAddToWorkup={handleAddToWorkup}
          onCancelAddToWorkup={handleCancelAddToWorkup}
        />
      )}
    </div>
  );
};

export default WorkupHomePage;

const AddToWorkUpActions = ({ onAddToWorkup, onCancelAddToWorkup }) => {
  return (
    <div className="button-container">
      <Stack direction="row">
        <Link
          className="cancel"
          variant="outlined"          
          onClick={onCancelAddToWorkup}
        >
          Cancel
        </Link>
      </Stack>{" "}
      <Stack direction="row">
        <Button
          className="submit"
          type="button"
          variant="contained"
          onClick={onAddToWorkup}
        >
          Add to Workup
        </Button>
      </Stack>
    </div>
  );
};
