import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./index.scss";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Filter from "../../filter/Filter";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
  Collapse,
  serverError,
} from "@mui/material";
import "./index.scss";
import { Button, FormControlLabel, Checkbox } from "@mui/material";
import SearchFilter from "../../filter/SearchFilter";
import dayjs from "dayjs";
import WorkupMenu from "./workupMenu/Workupmenu";
import Constants from "../../../languages/en.json";
import { ColumnSort } from "../../columnSort/ColumnSort";
import SearchWorkups from "../searchWorkups";
import WorkupFilters from "./workupFilters/WorkupFilters";
import { ServerError } from "../../serverError/ServerError";
import { NoResultsFound } from "../../noResultsFound/NoResultsFound";
import WorkupPagination from "./workupPagination/WorkupPagination.js";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const WorkupTable = ({
  posts,
  onSortChanged,
  sortParam,
  filterValues,
  filters,
  updateFilters,
  serverError,
  searchText,
  onSearchTextChange,
  workupDetails,
  postsPerPage,
  totalPosts,
  onChange,
  lastIndex,
  startIndex,
  currentPosts,
  isAddWorkup,
  onWorkupSelected,
  selectedWorkups,
  expandedRows,
  onRowExpanded,
  onNewWorkup
}) => {
  const _workupTableHeader = Constants.en.workupHomePage.workupTable;

  const getTable = () => {
    return (
      <>
        <div className="workup-table-root">
          <Box>
            <TableContainer className="table-container">
              <Table className="table" aria-label="collapsible table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell className="table-header-cell"></TableCell>
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading ">
                          {_workupTableHeader.workup_name}
                        </span>

                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="workup_name"
                            sortDirection={sortParam["workup_name"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>

                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading ">
                          {_workupTableHeader.patient_id}
                        </span>
                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="patient_id"
                            sortDirection={sortParam["patient_id"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading ">
                          {_workupTableHeader.patient_name}
                        </span>
                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="patient_name"
                            sortDirection={sortParam["patient_name"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>

                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">
                          {_workupTableHeader.last_updated}
                        </span>
                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="result_dt"
                            sortDirection={sortParam["result_dt"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>

                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">
                          {_workupTableHeader.last_updated_by}
                        </span>
                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="last_updated_by"
                            sortDirection={sortParam["last_updated_by"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>

                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">
                          {_workupTableHeader.status}
                        </span>
                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="status"
                            sortDirection={sortParam["status"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>

                    <TableCell className="table-header-cell"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className="table-body">
                  {posts.map((workup, index) => (
                    <CollapsibleTable
                      row={workup}
                      key={index}
                      workupDetails={workupDetails[workup.id]}
                      rowExpand={_workupTableHeader}
                      isAddWorkup={isAddWorkup}
                      onWorkupSelected={onWorkupSelected}
                      isSelected={selectedWorkups.includes(workup.id)}
                      isExpanded={expandedRows.includes(workup.id)}
                      onExpanderToggled={onRowExpanded}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
        <WorkupPagination
          postsPerPage={postsPerPage}
          totalPosts={totalPosts}
          onChange={onChange}
          lastIndex={lastIndex}
          startIndex={startIndex}
          currentPosts={currentPosts}
        />
      </>
    );
  };

  const getComponent = () => {
    if (serverError) {
      return <ServerError />;
    }

    if (posts.length == 0) {
      return <NoResultsFound></NoResultsFound>;
    }

    return getTable();
  };
  const handleNewWorkup = () => {
    localStorage.removeItem("allTestResultsData");
    localStorage.removeItem("allPatientData");
    localStorage.removeItem("workupname");
    onNewWorkup();
  };

  return (
    <Box>
      <div className="workup-root">
        <div className="left-sec">
          <SearchWorkups
            onSearchTextChange={onSearchTextChange}
            searchText={searchText}
          />
        </div>

        <div className="right-sec">
          <WorkupFilters
            className="filter-component"
            updateFilters={updateFilters}
            filters={filters}
            filterValues={filterValues}
          />

          <Button
            className="create-workup"
            variant="outlined"
            onClick={handleNewWorkup}
          >
            New Workup
          </Button>
        </div>
      </div>
      {getComponent()}
    </Box>
  );
};

export default WorkupTable;

function CollapsibleTable({
  row,
  workupDetails,
  rowExpand,
  isAddWorkup,
  onWorkupSelected,
  isSelected,
  isExpanded,
  onExpanderToggled,
}) {
  const [open, setOpen] = React.useState(false);
  const { details: { panels = [], workup } = {} } = workupDetails || {};
  const { id } = row;
  //console.log("open", open, id);
  const handleToggleClick = () => {
    onExpanderToggled(row, !isExpanded);
  };

  const handleCheckboxSelection = (e) => {
    onWorkupSelected(row, !isSelected);
  };

  var modalOpen = "true";

  const getExpander = () => {
    return (
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={handleToggleClick}
      >
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    );
  };

  const getCheckbox = () => {
    return (
      <FormControlLabel
        label={""}
        control={
          <Checkbox
            multiple
            checked={isSelected}
            onChange={handleCheckboxSelection}
            sx={{
              [`&, &.Mui-checked`]: {
                color: "#CF2F44",
              },
            }}
          />
        }
      />
    );
  };

  return (
    <React.Fragment>
      <TableRow className="table-body">
        <TableCell>{isAddWorkup ? getCheckbox() : getExpander()}</TableCell>
        <TableCell>
          <Link
            to={`/antigram-panel/${row?.id}`}
            state={modalOpen}
            target="_blank"
          >
            {row.workup_name}
          </Link>
        </TableCell>
        <TableCell>
          {row.patient_id === null || row.patient_id === undefined ? (
            "-"
          ) : (
            <span>{row.patient_id}</span>
          )}
        </TableCell>
        <TableCell>
          {row.patient_name === null || row.patient_name === undefined ? (
            "-"
          ) : (
            <Link to="#">{row.patient_name}</Link>
          )}

          <Routes>
            <Route path="" element={""} />
          </Routes>
        </TableCell>
        <TableCell>
          {row.result_dt === null || row.result_dt === undefined ? (
            "-"
          ) : (
            <span>{dayjs(row.result_dt).format("MM/DD/YYYY - HH:MM")}</span>
          )}
        </TableCell>
        <TableCell>
          {row.last_updated_by === null || row.last_updated_by === undefined ? (
            "-"
          ) : (
            <span>{row.last_updated_by}</span>
          )}
        </TableCell>
        <TableCell>
          <span
            className="status"
            style={{
              backgroundColor:
                (row.status.toLowerCase() === "new" ? "#D0E7FE" : null) ||
                (row.status.toLowerCase() === "in progress"
                  ? "#F4CF71"
                  : null) ||
                (row.status.toLowerCase() === "in review"
                  ? " #ED8B00"
                  : null) ||
                (row.status.toLowerCase() === "completed" ? "#A4EDB0" : null),
            }}
          >
            {row.status}
          </span>
        </TableCell>
        {isAddWorkup ? (
          <TableCell>{isAddWorkup ? getExpander() : undefined}</TableCell>
        ) : (
          <TableCell>
            <WorkupMenu workupId={id} status={row.status} />
          </TableCell>
        )}
      </TableRow>
      <TableRow className="expand-row-container">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell rowSpan={2} className="expand-row-label">
                    {rowExpand.workup_details}
                  </TableCell>
                  <TableCell className="expand-row-headers">
                    {rowExpand.submission_date}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="expand-row-label">
                    {row.submitted_date === null ||
                    row.submitted_date === undefined ? (
                      ""
                    ) : (
                      <span>
                        {dayjs(row.submitted_date)
                          .local()
                          .format("MM/DD/YYYY- HH:MM")}
                      </span>
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell rowSpan={6} className="expand-row-label">
                    {rowExpand.panel_details}
                  </TableCell>
                  <TableCell className="expand-row-headers">
                    {rowExpand.panel_type}
                  </TableCell>
                  <TableCell className="expand-row-headers">
                    {rowExpand.sample_id}
                  </TableCell>
                  <TableCell className="expand-row-headers">
                    {rowExpand.sample_date}
                  </TableCell>
                  <TableCell className="expand-row-headers">
                    {rowExpand.lab}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {panels.map((panel, index) => (
                  <TableRow key={index}>
                    <TableCell></TableCell>
                    <TableCell className="expand-row-label">
                      {panel.panel_type}
                    </TableCell>
                    <TableCell className="expand-row-label">
                      {panel.sample.hashed}
                    </TableCell>
                    <TableCell className="expand-row-label">
                      {panel.sample.sample_date === null ||
                      panel.sample.sample_date === undefined ? (
                        ""
                      ) : (
                        <span>
                          {dayjs(panel.sample.sample_date)
                            .local()
                            .format("MM/DD/YYYY")}
                        </span>
                      )}
                    </TableCell>
                    <TableCell className="expand-row-label">
                      {panel.laboratory}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
