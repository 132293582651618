import React, { useState } from "react";
import Constants from "../../../../languages/en.json";
import dayjs from "dayjs";
import "./index.scss";
import FormattedLabel from "../../../formattedLabel/FormattedLabel";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const AntigramTable = ({ apiAllData }) => {

  const antigramTableConst = Constants.en.antigramTable;
  const colOrder = antigramTableConst.apiColOrder;
  const allPanelsData = apiAllData && [apiAllData];

  const headerRowLabels = () => {
    const colOrderCst = antigramTableConst.cstColOrder;
    const headerCells = [];
    for (var j = 0; j < colOrder.length; j++) {
      if (colOrder[j] == "cell_number" || colOrder[j] == "Spc" || colOrder[j] == "Rh-hr" || colOrder[j] == "donor_number" || colOrder[j] == "tst-rslt") {
        headerCells.push(
          <td key={j} className={`hd-cell cl-${j + 1} ${colOrder[j]}`}>
            {colOrderCst[j]}
          </td>
        );
      } else {
          headerCells.push(
            <td
              key={j}
              className={`hd-cell cl-${j + 1} ${colOrder[j]}`}
              colKey={colOrder[j]}
            >
              <FormattedLabel j={colOrder[j]}></FormattedLabel>
              </td>)
        }
      }
    return headerCells;
  };

  const patientcellsrow = () => {
    const patientcells = [];
    var rowLabel;
    for (var j = 0; j < colOrder.length; j++) {

      if (colOrder[j] == "Rh-hr") {
        rowLabel = antigramTableConst.tableFooterDetails.PatientCells;
      } else {
        rowLabel = "";
      }
      patientcells.push(
        <td
          key={j}
          className={`pt-cell cl-${j + 1} ${colOrder[j]}`}
        >
          {rowLabel}
        </td>
      );
    }
    return patientcells;
  };

  const anitigenColsPanel = (i, panelno) => {
    const eachCol = [];
    const getPanelData = allPanelsData && allPanelsData[panelno];

    for (var j = 0; j < colOrder.length; j++) {

      if (colOrder[j] == "cell_number") {
        eachCol.push(
          <td key={j} className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]}`}>{i + 1}</td>
        );
      }
      else if (colOrder[j] == "tst-rslt") {
        eachCol.push(
          <td key={j} className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]}`}>
            {getPanelData?.results[i]?.value}
          </td>
        )
      }
      else {
        eachCol.push(
          <td
            key={j}
            panelno={panelno}
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]} panel-${panelno + 1}
            pnl-${panelno + 1}rw-${i + 1}`}>
            {getPanelData?.panel?.rows[i][colOrder[j]]}
          </td>
        );
      }
    }

    return eachCol;
  };

  const anitigenRowsPanel = (panelno) => {
    const getPanelData = allPanelsData && allPanelsData[panelno];
    const eachRow = [];
    for (var i = 0; i < getPanelData?.panel?.rows?.length; i++) {
      if (getPanelData?.panel.rows[i]?.cell_number == "AC") {
        eachRow.push();
      } 
      else {
        eachRow.push(
          <tr className={`row-${i + 1}`} key={`row-${i + 1}`}>{anitigenColsPanel(i, panelno)}</tr>
        );
      }
    }
    eachRow.push(<tr key={panelno}>{patientcellsrow()}</tr>);
    return eachRow;
  };

  const LoopPanels = (panelKey, key) => {
    return (
      <React.Fragment>
        <tbody className="antigram-header-data panel-a panel-a-header">
          <tr>
            <td className="panel-a-detail-row" colSpan={34}>
              <div className="panel-a-row">
                <span className="panel-name">
                  {antigramTableConst.panelDetails.panelName} {allPanelsData[key].panel.panel_type}
                </span>
                <span className="lot-no">
                  <b>{antigramTableConst.panelDetails.lotNo}</b> {allPanelsData &&
                    allPanelsData[key]?.panel?.lot_number}
                </span>
                <span className="exp-dt">
                  <b>{antigramTableConst.panelDetails.exp}</b> {dayjs(allPanelsData[key]?.panel?.expiration_date).local().format("MM/DD/YYYY HH:MM")}
                </span>
                <span className="sample-id">
                  <b>{antigramTableConst.panelDetails.sampleId}</b> {allPanelsData[key]?.panel?.sample.unhashed}
                </span>
                <span className="see-details-label">
                  {antigramTableConst.panelDetails.SeeDetails}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody className="antigram-main-data panel-a panel-a-table">
          {anitigenRowsPanel(key)}
        </tbody>
      </React.Fragment>
    );
  };

  return (
    <div className="test-table-wrapper">
      <table>
        <tbody className="antigram-header-data antigram-header-sticky">
          <tr className="table-group-heading">
            <td colSpan={3}></td>
            <td colSpan={8}>{antigramTableConst.tableHeaderGroup.RhHr}</td>
            <td colSpan={6}>{antigramTableConst.tableHeaderGroup.Kell}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Duffy}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Kidd}</td>
            <td>{antigramTableConst.tableHeaderGroup.SexLinked}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Lewis}</td>
            <td colSpan={4}>{antigramTableConst.tableHeaderGroup.MNS}</td>
            <td>{antigramTableConst.tableHeaderGroup.P}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Lutheran}</td>
            <td></td>
            <td colSpan={2}>
              {antigramTableConst.tableHeaderGroup.TestsResults}
            </td>
          </tr>

          <tr className="cell-row-header">{headerRowLabels()}</tr>
        </tbody>

        {allPanelsData &&
          allPanelsData.map((panelKey, key) => {
            return (
              <React.Fragment key={key}>
                {LoopPanels(panelKey, key)}
              </React.Fragment>
            );
          })}

        <tbody>
          <tr className="table-group-footer">
            <td colSpan={3}>
              {antigramTableConst.tableFooterDetails.ModeReactivity}
            </td>
            <td colSpan={8}>
              {antigramTableConst.tableFooterDetails.CAntiglobulin}
            </td>
            <td colSpan={11}>
              {antigramTableConst.tableFooterDetails.Antiglobulin}
            </td>
            <td colSpan={4}>
              {antigramTableConst.tableFooterDetails.Variable}
            </td>
            <td colSpan={3}>{antigramTableConst.tableFooterDetails.Cold}</td>
            <td colSpan={2}>{antigramTableConst.tableFooterDetails.Var}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default AntigramTable;
