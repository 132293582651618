import React from 'react';
import './formattedLabel.scss'
import Constants from '../../languages/en.json'

const formattedRowLabel = Constants.en.antigramTable.formattedLabel

const FormattedLabel = (props) => {
    const j = props.j;
    
    if (formattedRowLabel[j].format == true && (formattedRowLabel[j].type == "sup")) {
        return (
            <React.Fragment>
                {formattedRowLabel[j].classification}
                <sup className='sup-script'>{formattedRowLabel[j].script}</sup>
            </React.Fragment>
        )
    }
    else if (formattedRowLabel[j].format == true && (formattedRowLabel[j].type == "sub")) {
        return (
            <React.Fragment>{formattedRowLabel[j].classification}
                <sub className='sub-script'>{formattedRowLabel[j].script}</sub>
            </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>{formattedRowLabel[j].classification}</React.Fragment>
            )
    }
}
export default FormattedLabel;