import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SlashMask, CrossMask, EllipseMask } from "../../../utils/icons";
import "./index.scss";

const MenuDropdown = ({
  children,
  handleChange,
  activeAnchor = null,
  colKey,
  rowNumber,
  keyFwd,
  className,
  panelNo,
  handleClearState,
  handleClickState,
  statusKey
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [transformPosition,setTransformPosition] = React.useState("top");
  const [anchorPosition,setAnchorPosition] = React.useState("bottom");
  const MENU_HEIGHT=130;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if(statusKey === "COMPLETED"){
      return false;
    }
    if(event.clientY+MENU_HEIGHT >= window.innerHeight){ 
      setTransformPosition("bottom");
      setAnchorPosition("top");
    }
    else{
      setTransformPosition("top");
      setAnchorPosition("bottom");
    }
    setAnchorEl(event.currentTarget);
    handleClickState(className);
    if(anchorEl != null){
      handleClose();
    }
  };
  const handleClose = (id) => {
    setAnchorEl(null);
    handleClearState(null);
  };
  const manuItems = [
    {
      iconSrc: "CrossMask",
    },
    {
      iconSrc: "SlashMask",
    },
    {
      iconSrc: "EllipseMask",
    },
  ];

  return (
    <React.Fragment>
      <td
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{}}
        className={open ? `${className} dropdwon-open` : className}
        onClick={handleClick}
        key={keyFwd}
      >
        {children}
      </td>
      <Menu
        className="MuiMenu-root manual-markup-modal"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        elevation={0}
        anchorOrigin={{
          vertical: anchorPosition,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: transformPosition,
          horizontal: "center",
        }}
      >
        {manuItems &&
          manuItems.map((icon, idx) => {
            return (
              <MenuItem
                key={idx}
                onClick={() => handleChange(idx, colKey, rowNumber, panelNo,handleClose())}
                className={activeAnchor == idx ? `${idx} active` : `${idx}`}
              >
                {icon?.iconSrc == "CrossMask" ? (
                  <CrossMask fillColor={activeAnchor == idx && "white"} />
                ) : icon?.iconSrc == "SlashMask" ? (
                  <SlashMask fillColor={activeAnchor == idx && "white"} />
                ) : icon?.iconSrc == "EllipseMask" ? (
                  <EllipseMask fillColor={activeAnchor == idx && "white"} />
                ) : (
                  ""
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </React.Fragment>
  );
};
export default MenuDropdown;