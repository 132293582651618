import React from "react";
import PositionedSnackbar from "../testTable/PositionedSnackbar";
import {ReactComponent as Error} from "../../assets/images/Error.svg"

export const ServerError=()=>{
    return (
        <div className="no-result-found">
          <div id="snackbar"></div>
          <div className="circle"><Error></Error></div>

          <h3>iOoops Something Went Wrong</h3>
          <p>
            Try again to find what you’re looking for.
          </p>
        </div>
    );
}