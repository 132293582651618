import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PatientData } from "./views/patientData/PatientData";
import { Routes, Route, Navigate } from "react-router-dom";
import AntigramPanel from "./components/antigramPanel/AnitgramPanel";
import { currentConfig } from "./utils/amplify-config";
import { Amplify, Auth } from "aws-amplify";
import ProtectedRoute from "./routes";
import "./App.scss";
import PatientTable from "./components/patientTable/patientTable";
import PatientHome from "./components/patientTable/patientHome";
import CreatePatient from "./components/createPatient/createPatient";
import WorkupHomePage from "./components/workups/workupHomePage/WorkupHomePage";
import { SpinnerProvider } from "./components/spinner/spinner";
import SingleWorkup from "./views/singleWorkup/SingleWorkup";
import TestResultsPanel from "./components/testTable/antigramPanel/TestResultsPanel";

Amplify.configure(currentConfig);
// console.log(currentConfig,"checkENVconfig")
function App() {
  const [userSession, setUserSession] = useState({});
  const theme = createTheme({
    typography: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
    },
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      // Auth.federatedSignIn();
      Auth.federatedSignIn({ provider: "Gigya-DEV" });
    });
    getSession();
  }, []);

  const getSession = async () => {
    await Auth.currentSession().then((userSession) => {
      setUserSession(userSession);
      if (userSession?.idToken?.jwtToken == null) {
        window.location.reload();
      }
    });
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SpinnerProvider>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute
                  component={() => <PatientData addTestResults={false} />}
                />
              }
            />
            <Route
              exact
              path="/:workupId"
              element={
                <ProtectedRoute
                  component={() => <PatientData addTestResults={false} />}
                />
              }
            />
            <Route
              exact
              path="/workup-home"
              element={<ProtectedRoute component={WorkupHomePage} />}
            />
            <Route
              exact
              path="/antigram-panel/:panelId"
              element={<ProtectedRoute component={AntigramPanel} />}
            />
            <Route
              exact
              path="/patient-home"
              element={
                <ProtectedRoute
                  component={() => <PatientHome addPatientDetails={false} />}
                />
              }
            />
            <Route
              exact
              path="/patient-home/:workupId"
              element={
                <ProtectedRoute
                  component={() => <PatientHome addPatientDetails={false} />}
                />
              }
            />
            <Route
              exact
              path="/create-patient"
              element={<ProtectedRoute component={CreatePatient} />}
            />
            <Route
              exact
              path="/single-workup"
              element={<ProtectedRoute component={SingleWorkup} />}
            />
            <Route
              exact
              path="/single-workup/:workupId"
              element={<ProtectedRoute component={SingleWorkup} />}
            />
            <Route
              exact
              path="/view-test-results/:panelId/:date"
              element={<ProtectedRoute component={TestResultsPanel} />}
            />
          </Routes>
        </SpinnerProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
