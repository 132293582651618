import React, { useEffect, useState } from "react";
import AntigramTable from "./antigramTable/AntigramTable.js";
import { getSingleResult } from "../../../Redux/Actions/results.js";
import { useParams } from 'react-router-dom';
import "./index.scss";
import Button from "@mui/material/Button";
import Constants from "../../../languages/en.json";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Tooltip from '@mui/material/Tooltip';
import './index.scss';
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const HeaderComp = Constants.en.antigramTable.headerComponents;

const TestResultsPanel = () => {
  const [apiResponse, setapiResponse] = useState([]);
  const { panelId , date } = useParams();

  useEffect(() => {
    getWorkupsCall();
  }, []);

  const getWorkupsCall = async () => {
    const res = await getSingleResult(panelId);
    if (res) {
      const responseData = res;
      setapiResponse(responseData);
    }
  }

  function NewAntigramHeader() {
    const antigramHederData = apiResponse?.panel;
    const { sample, expiration_date } =
      antigramHederData;

    return (
      <div className="test-table-antigram-panel-header-wrapper-view">
        <div className="antigram-panel-header">
          <div className="antigram-title-row">
            <h1 className="workuptitle">{sample.unhashed}</h1>
            <span className="last-modified">
              {date === undefined
                ? "-"
                : dayjs(date).local().format("MM/DD/YYYY HH:MM")}
            </span>
            <span className="hyphen"> - </span>
            <span className="view-only"> {HeaderComp.ViewOnly}</span>
            <span> <Tooltip title="You need to add to workup to do any edits" arrow placement="top">
              <ErrorOutlineOutlinedIcon className="error-icon" color="error" /></Tooltip>
            </span>
            <FileDownloadOutlinedIcon className="download-button" />
            <Button className="wk-submit-btn" variant="contained">
              {HeaderComp.AddToWorkup}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    apiResponse && (Object.keys(apiResponse).length !== 0) && <div className="antigram-panel-root">
      <NewAntigramHeader />
      <AntigramTable
        apiAllData={apiResponse} />
    </div>
  );
};

export default TestResultsPanel;
