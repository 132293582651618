import React, { useState, useEffect, useRef, useContext } from "react";
import TestTable from "../../components/testTable/TestTable";
import PaginationView from "../../components/pagination/PaginationView";
import { getResults } from "../../Redux/Actions/results";
import { getDatePartDayJs, getTableDateTimeFormat } from "../../utils/date";
import { getMatchingField as getMatchingFieldUtil } from "../../utils/search";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFiltersFromApi,
  saveFiltersToApi,
} from "../../Redux/Actions/test-table-filter";
import { nameFormatter } from "../../utils/tableValueFormatter";
import Constants from "../../languages/en.json";
import { SpinnerContext } from "../../context/spinner-context.js";
import {
  caseInsensitiveTextSortAsc,
  caseInsensitiveTextSortDesc,
} from "../../utils/column-sort.js";
import { communicationStorage } from "../../services/communicationService";
import { SuccessPopup } from "../../components/successPopup/successPopup";

const statusSort = Constants.en.testTable.statusSorting;

const matcher = {
  result_dt: (value, searchText) => {
    return getTableDateTimeFormat(value).includes(searchText);
  },
};

const getMatchingField = (row, searchText) => {
  return getMatchingFieldUtil(row, searchText, matcher);
};

const getDataSatisfyingSearch = (data, searchText) => {
  if (data.length === 0 || searchText == "") {
    return data;
  }

  const lowerCaseSearchText = searchText.toLowerCase();

  const filteredData = data.filter((p) => {
    const matchingField = getMatchingField(p, lowerCaseSearchText);
    return matchingField ?? false;
  });

  return filteredData;
};

const getFilteredData = (data, filters) => {
  let filteredData = data;

  if (filters.statuses && filters.statuses.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.statuses.includes(p.status)
    );
  }

  if (filters.panelTypes && filters.panelTypes.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.panelTypes.includes(p.panel_type)
    );
  }

  if (filters.laboratories && filters.laboratories.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.laboratories.includes(p.laboratory)
    );
  }

  if (filters.startDate) {
    const startDateDayJS = getDatePartDayJs(filters.startDate);

    filteredData = filteredData.filter((p) => {
      const dt = getDatePartDayJs(new Date(p.result_dt));

      return dt >= startDateDayJS;
    });
  }

  if (filters.endDate) {
    const endDateDayJS = getDatePartDayJs(filters.endDate);

    filteredData = filteredData.filter((p) => {
      const dt = getDatePartDayJs(new Date(p.result_dt));
      return dt <= endDateDayJS;
    });
  }

  return filteredData;
};

const sortAscFuncs = {
  status: (a, b) =>
    statusSort[a["status"].replace(" ", "")] <
    statusSort[b["status"].replace(" ", "")]
      ? -1
      : 1,
  regular: (col) => (a, b) => caseInsensitiveTextSortAsc(a[col], b[col]),
};

const sortDescFuncs = {
  status: (a, b) => sortAscFuncs.status(a, b) * -1,
  regular: (col) => (a, b) => caseInsensitiveTextSortDesc(a[col], b[col]),
};

const getFlattenedPosts = (posts) => {
  return posts?.map((p) => ({
    id: p.id,
    sample_id: p?.sample_id,
    result_dt: p?.date_time,
    patient_id: p?.patient?.id,
    lot_number: p.lot_number,
    patient_name: nameFormatter(p?.patient?.last_name, p?.patient?.first_name),
    laboratory: p?.workup?.laboratory,
    panel_type: p?.panel_type,
    workup_name: p?.workup?.name,
    workup_id: p?.workup?.id,
    status: p?.workup?.status,
    antigram_reference_key: p?.antigram_reference_key,
    test_result_reference_key: p?.test_result_reference_key,
  }));
};

export const PatientData = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let isAddToWorkupFlow = false;

  let addTestResults;
  if (!props.addTestResults) {
    const location = useLocation();
    addTestResults = location?.state?.addTestResults;
    isAddToWorkupFlow = location.state?.isAddToWorkupFlow;
  } else {
    addTestResults = false;
  }

  const postsRef = useRef({ allPosts: [], showLaboratory: true });
  const {
    current: { allPosts, showLaboratory },
  } = postsRef;

  // Filtered and sorted posts
  const [posts, setPosts] = useState([]);
  const [apiError, setApiError] = useState();
  const [filters, setFilters] = useState({}); // Selected filters
  const [filterValues, setFilterValues] = useState({ laboratories: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [sortParam, setSortParam] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(communicationStorage.isAddToWorkupFlowSuccess);
  console.log(communicationStorage.isAddToWorkupFlowSuccess);
  communicationStorage.isAddToWorkupFlowSuccess=false;
  const { changeSpinnerStatus } = useContext(SpinnerContext);

  // Patient Data //

  const updateFromApiRef = useRef();
  const updateFromApi = async () => {
    try {
      const res = await getResults();
      if (res) {
        console.log("Result", res?.results);
        updateAllPosts(res?.results);
      }
      setApiError(null);
    } catch (e) {
      setApiError({ message: "Error occured" });
    }
  };

  const getPostsToShow = (searchText, filters) => {
    const flattenedPosts = getFlattenedPosts(postsRef.current.allPosts);
    let postsToShow = getDataSatisfyingSearch(flattenedPosts, searchText);
    postsToShow = getFilteredData(postsToShow, filters);

    return postsToShow;
  };

  updateFromApiRef.current = updateFromApi;

  useEffect(() => {
    changeSpinnerStatus(true);
    initialize();

    const timer = setInterval(() => updateFromApiRef.current(), 30000);
    return () => clearInterval(timer);
  }, []);

  const initialize = async () => {
    console.log("PatientData initialize");
    await updateFromApi();

    var filters = await getFiltersFromApi();

    if (filters) {
      setFilters(JSON.parse(filters) || {});
    }
    changeSpinnerStatus(false);
  };

  const updateAllPosts = (posts) => {
    postsRef.current.allPosts = posts;
    var laboratories = [...new Set(posts.map((p) => p.workup.laboratory))];
    postsRef.current.showLaboratory = laboratories.length > 1;
    setFilterValues({
      ...filterValues,
      laboratories: laboratories.map((l) => ({ name: l })),
    });
    updatePostsToShow();
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // Get current posts //
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // sorting for TestTable //

  const handleSortChanged = (field, sortDirection) => {
    setSortParam({ [field]: sortDirection });
  };

  //  On Page Change //
  const onChange = (event, nextPage) => {
    setCurrentPage(nextPage);
  };

  const updateFilters = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
    saveFilters(filters);
    saveFiltersToApi(filters);
  };

  const saveFilters = (filters) => {
    // to be done - call api here

    localStorage.setItem("filters", JSON.stringify(filters));
  };

  useEffect(() => {
    updatePostsToShow();
  }, [searchText, filters, sortParam]);

  const updatePostsToShow = () => {
    const filteredPosts = getPostsToShow(searchText, filters);
    const sortedPosts = sortDataList(filteredPosts, sortParam);

    setPosts(sortedPosts);
  };

  const sortDataList = (data, sortParam) => {
    let sortFunc;

    const keys = Object.keys(sortParam);
    // no sort param available
    if (keys.length == 0) {
      sortFunc = sortDescFuncs["regular"]("result_dt");
    } else {
      const field = keys[0];

      // Ascending
      if (sortParam[field] == 0) {
        if (sortAscFuncs[field]) {
          sortFunc = sortAscFuncs[field];
        } else {
          sortFunc = sortAscFuncs["regular"](field);
        }
      }

      // Descending
      else if (sortParam[field] == 1) {
        if (sortDescFuncs[field]) {
          sortFunc = sortDescFuncs[field];
        } else {
          sortFunc = sortDescFuncs["regular"](field);
        }
      }
    }

    return data.sort(sortFunc);
  };

  const handleSearchText = (searchText) => {
    setSearchText(searchText);
    setCurrentPage(1);
  };

  const handleAddToWorkup = (testResult) => {
    communicationStorage.selectedTestResult = testResult;
    navigate(`/workup-home`, {
      state: {
        isAddToWorkupFlow: true,
        source: "testresult",
        selectedTestResult: testResult,
      },
    });
  };

  const handleSuccessPopupClose = () => {
    setIsSuccessPopupOpen(false);
  };

  return (
    <div>
      {isSuccessPopupOpen && (
        <SuccessPopup onClose={handleSuccessPopupClose}>
          Workup created: A new workup has been created with the selected
          results.
        </SuccessPopup>
      )}
      <TestTable
        addTestResults={addTestResults}
        posts={currentPosts}
        setPosts={setPosts}
        filters={filters}
        filterValues={filterValues}
        updateFilters={updateFilters}
        showLaboratory={showLaboratory}
        onSearchTextChange={handleSearchText}
        searchText={searchText}
        onSortChanged={handleSortChanged}
        sortParam={sortParam}
        serverError={apiError}
        onAddToWorkup={handleAddToWorkup}
      />
      <PaginationView
        addTestResults={addTestResults}
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        onChange={onChange}
        lastIndex={indexOfLastPost}
        startIndex={indexOfFirstPost}
        currentPosts={currentPosts}
      />
    </div>
  );
};
