// Checks if there is any field where the searchtext matches.
export const caseInsensitiveInclude = (value, searchText) => {
  return (value || "").toString().toLowerCase().includes(searchText);
};

export const getMatchingField = (obj, searchText, matcher = {}) => {
  const keys = Object.keys(obj);

  return keys.find((k) => {
    const func = matcher[k] ? matcher[k] : caseInsensitiveInclude;

    return func(obj[k], searchText);
  });
};
