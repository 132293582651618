import Api from "../../utils/apiConfig";

export const getFiltersFromApi = async () => {
    try {
      const response = await Api.get(
        "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/me",
        {}
      );
      if (response) {
        // console.log("me response", response);
        return response?.data?.results_filter;
      }
    } catch (err) {
    //   console.log("err", err);
    }
  };

  export const saveFiltersToApi = (filters) => {
    try {
        
      const response = Api.post(
        "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/me/kvs/results_filter",
        {value:JSON.stringify(filters)}
      );
      if (response) {
        
        return response?.data;
      }
    } catch (err) {
    //   console.log("err", err);
    }
  };