import React, { useState } from "react";
import AlertDialogModal from "../../alertDialogModal/AlertDialogModal";
import SubmitModalSelector from "../submitModalSelector/submitModalSelector";

const HeaderReviewModal = (props) => {

 
  const [onRecChange, setOnRecChange] = useState("#F3F6F7");
  const handleRecColor = (val) => {
    setOnRecChange(val);
    setOnManualChange("#F3F6F7");
  };
  const [onManualChange, setOnManualChange] = useState("#F3F6F7");
  const handleManualColor = (val) => {
    setOnManualChange(val);
    setOnRecChange("#F3F6F7");
  };
  const dualogTitleContent = () => {
    return (
      <div className="dialog-content">
        Select Final Analysis.
        <span
          style={{
            fontSize: "14px",
            paddingLeft: "5px",
            fontWeight: "400",
            color: "#000000",
          }}
        >
          (Step 1 of 2)
        </span>
      </div>
    );
  };
  const Styles={
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "100%",
        height: "500px",
        borderRadius: "8px",
        padding: "30px",
      },
      "& .MuiDialogTitle-root": {
        padding: "0px",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
        "& .MuiTypography-root": {
          "& .MuiFormControl-root": {
            width: "100%",
            "& .MuiFormGroup-root": {
              "& .recommended-container": {
                
                display: "flex",
                flexDirection: "column",
                background: onRecChange ? onRecChange : "#F3F6F7",
                border:
                  onRecChange === "#E9F0F3" ? "2px solid #425E66" : "0px",
                padding: "2px 20px 0px 10px",
                borderRadius: "8px",
                margin: "10px",
                // flexGrow: 0,
                "& .classification-tab": {
                  paddingLeft: "25px",
                  paddingBottom:"10px",
                  // width: "100%",
                  "& .label-row .label":{
                    padding: "15px 0 28px",
                  }
                },
              },
              "& .manual-container": {
                display: "flex",
                flexDirection: "column",
                background: onManualChange ? onManualChange : "#F3F6F7",
                border:
                  onManualChange === "#E9F0F3" ? "2px solid #425E66" : 0,
                padding: "5px 20px 0px 16px",
                borderRadius: "8px",
                margin: "10px",
                flexGrow: 0,
                "& .classification-tab": {
                  paddingLeft: "25px",
                  paddingBottom:"10px",
                  "& .label-row .label":{
                    padding: "15px 0 28px",
                  }
                },
              },
            },
          },
        },
        "& .MuiTypography-body1 ": {
          fontWeight: "500",
          color: "#000000",
        },
      },
    },
  }
  // console.log(onManualChange);
  return (
    <AlertDialogModal
      sx={Styles}
      dialogButtonName="test"
      dialogTitle={dualogTitleContent()}
      dialogContent={
        <SubmitModalSelector
          handleManualColor={handleManualColor}
          handleRecColor={handleRecColor}
          classificRule={props?.classificRule}
        />
      }
      btnYesText="Next"
      btnNoText="Cancel"
      open={props?.reviewOpen}
      handleClickOpen={props?.handleReviewClickOpen}
      handleClose={props?.handleReviewClose}
      handleProceed={props?.handleClickOpen}
    />
  );
};
export default HeaderReviewModal;
