import React, { useEffect, useState } from "react";
import MenuDropdown from "../menuDropdown/MenuDropDown";
import HeaderMenuDropDown from "../menuDropdown/HeaderMenuDropDown";
import AlertNote from "../AlertNotification/AlertNote";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Constants from "../../../languages/en.json";
import dayjs from "dayjs";
import "./index.scss";
import FormattedLabel from "../../formattedLabel/FormattedLabel";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const AntigramTable = ({
  apiAllData,
  rmToggle,
  handleRMToggle,
  handleRuleUpdates,
}) => {
  const antigramTableConst = Constants.en.antigramTable;
  /* Set local storage objects */
  localStorage.setItem(
    "localApiAllDataOriginal",
    JSON.stringify({ ...apiAllData })
  );
  if (localStorage.getItem("localApiAllDataModified") == null) {
    localStorage.setItem(
      "localApiAllDataModified",
      JSON.stringify({ ...apiAllData })
    );
  }

  const [localApiAllData, setLocalApiAllData] = useState({ ...apiAllData });
  useEffect(() => {
    updateData();
  }, [rmToggle]);

  /* Re-render page with toglle of manual recommonded mode */
  const updateData = () => {
    if (rmToggle == true) {
      let mdfStrigify = JSON.parse(
        localStorage.getItem("localApiAllDataModified")
      );
      setLocalApiAllData({ ...mdfStrigify });
      handleRuleUpdates(mdfStrigify?.classifications?.algorithm_solution);
    } else if (rmToggle == false) {
      let orgStrigify = JSON.parse(
        localStorage.getItem("localApiAllDataOriginal")
      );
      setLocalApiAllData({ ...orgStrigify });
      handleRuleUpdates(orgStrigify?.classifications?.algorithm_solution);
    }
  };

  const updateModifiedData = () => {
    const strigifyData = JSON.stringify(localApiAllData);
    localStorage.setItem("localApiAllDataModified", strigifyData);
    updateData();
  };

  const allPanelsData = localApiAllData?.panels && localApiAllData?.panels;
  let rldClassificObj = localApiAllData?.classifications?.algorithm_solution;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeAnchor, setActiveAnchor] = React.useState(null);
  const [headerActiveAnchor, setHeaderActiveAnchor] = React.useState(null);

  const handleClearState = (val) => {
    setActiveAnchor(val);
  };
  const handleHdDropdnClearState = (val) => {
    setHeaderActiveAnchor(val);
  };
  const [selectedCol, setSelectedCol] = useState({});
  const [selectedRow, setSelectedRow] = useState({});

  const colOrder = antigramTableConst.apiColOrder;
// console.log(apiAllData)
  const headerRowLabels = () => {
    const colOrderCst = antigramTableConst.cstColOrder;
    const headerCells = [];

    const ruleOuts = rldClassificObj?.rule_outs;
    const ruleOutsSlash = rldClassificObj?.rule_outs_slash;
    const ruleins = rldClassificObj?.rule_ins;

    for (var j = 0; j < colOrder.length; j++) {
      if (colOrder[j] == "cell_number" || colOrder[j] == "Spc") {
        headerCells.push(
          <td key={j} className={`hd-cell cl-${j + 1} ${colOrder[j]}`}>
            {colOrderCst[j]}
          </td>
        );
      } else {
        let ruledClasses;
        if (ruleOuts && ruleOuts?.indexOf(colOrder[j]) > -1) {
          ruledClasses = "ruled-out-bg";
        } else if (ruleOutsSlash && ruleOutsSlash?.indexOf(colOrder[j]) > -1) {
          ruledClasses = "ruled-out-bg-slash";
        } else if (ruleins && ruleins?.indexOf(colOrder[j]) > -1) {
          ruledClasses = "ruled-in-bg";
        } else {
          ruledClasses = "";
        }

        let selectedColClass =
          colOrder[j] in selectedCol ? selectedCol[colOrder[j]] : "";
        if (colOrder[j] == "Rh-hr" || colOrder[j] == "donor_number" || colOrder[j] == "tst-rslt") {
          headerCells.push(
            <HeaderMenuDropDown
              key={j}
              className={`hd-cell cl-${j + 1} ${colOrder[j]
                } ${ruledClasses} ${selectedColClass}`}
              colHighlight={colHighlight.bind(this, colOrder[j])}
              updateHeaderRules={updateHeaderRules}
              statusKey={apiAllData?.response?.status}
              keyfwd={j}
              anchorEl={anchorEl}
              open={open}
              colKey={colOrder[j]}
              headerActiveAnchor={headerActiveAnchor}
              handleHdDropdnClearState={handleHdDropdnClearState}
              handleHdDropdnClickState={handleHdDropdnClickState}
            >
              {colOrderCst[j]}
            </HeaderMenuDropDown>
          );
        }
        else {
          headerCells.push(
            <HeaderMenuDropDown
              key={j}
              className={`hd-cell cl-${j + 1} ${colOrder[j]
                } ${ruledClasses} ${selectedColClass}`}
              colHighlight={colHighlight.bind(this, colOrder[j])}
              updateHeaderRules={updateHeaderRules}
              statusKey={apiAllData?.response?.status}
              keyfwd={j}
              anchorEl={anchorEl}
              open={open}
              colKey={colOrder[j]}
              headerActiveAnchor={headerActiveAnchor}
              handleHdDropdnClearState={handleHdDropdnClearState}
              handleHdDropdnClickState={handleHdDropdnClickState}
            >
              <FormattedLabel j={colOrder[j]}></FormattedLabel>
            </HeaderMenuDropDown>)
        }
      }
    }
    return headerCells;
  };

  const updateHeaderRules = (id, colType) => {
    const maskType = id == 0 ? "X" : id == 1 ? "/" : id == 2 ? "O" : "";

    if (rldClassificObj && rldClassificObj?.rule_outs == null) {
      rldClassificObj.rule_outs = [];
    }
    if (rldClassificObj && rldClassificObj?.rule_outs_slash == null) {
      rldClassificObj.rule_outs_slash = [];
    }
    if (rldClassificObj && rldClassificObj?.rule_ins == null) {
      rldClassificObj.rule_ins = [];
    }

    let rule_outs = rldClassificObj.rule_outs;
    let rule_outs_slash = rldClassificObj.rule_outs_slash;
    let rule_ins = rldClassificObj.rule_ins;

    var index;
    if (maskType == "X") {
      index = rule_outs && rule_outs.indexOf(colType);
      if (rule_outs && rule_outs.indexOf(colType) > -1) {
        rule_outs?.splice(index, 1);
        setHeaderActiveAnchor(null);
      } else {
        if (
          rule_outs_slash.indexOf(colType) != -1 &&
          rule_outs_slash.indexOf(colType) != undefined
        ) {
          index = rule_outs_slash && rule_outs_slash.indexOf(colType);
          rule_outs_slash?.splice(index, 1);
        }
        if (
          rule_ins.indexOf(colType) != -1 &&
          rule_ins.indexOf(colType) != undefined
        ) {
          index = rule_ins && rule_ins.indexOf(colType);
          rule_ins?.splice(index, 1);
        }
        rule_outs.push(colType);
        setHeaderActiveAnchor(id);
      }
    } else if (maskType == "/") {
      index = rule_outs_slash && rule_outs_slash.indexOf(colType);
      if (rule_outs_slash && rule_outs_slash.indexOf(colType) > -1) {
        rule_outs_slash?.splice(index, 1);
        setHeaderActiveAnchor(null);
      } else {
        if (
          rule_outs.indexOf(colType) != -1 &&
          rule_outs.indexOf(colType) != undefined
        ) {
          index = rule_outs && rule_outs.indexOf(colType);
          rule_outs?.splice(index, 1);
        }
        if (
          rule_ins.indexOf(colType) != -1 &&
          rule_ins.indexOf(colType) != undefined
        ) {
          index = rule_ins && rule_ins.indexOf(colType);
          rule_ins?.splice(index, 1);
        }
        rule_outs_slash.push(colType);
        setHeaderActiveAnchor(id);
      }
    } else if (maskType == "O") {
      index = rule_ins && rule_ins.indexOf(colType);
      if (rule_ins && rule_ins.indexOf(colType) > -1) {
        rule_ins?.splice(index, 1);
        setHeaderActiveAnchor(null);
      } else {
        if (
          rule_outs.indexOf(colType) != -1 &&
          rule_outs.indexOf(colType) != undefined
        ) {
          index = rule_outs && rule_outs.indexOf(colType);
          rule_outs?.splice(index, 1);
        }
        if (
          rule_outs_slash.indexOf(colType) != -1 &&
          rule_outs_slash.indexOf(colType) != undefined
        ) {
          index = rule_outs_slash && rule_outs_slash.indexOf(colType);
          rule_outs_slash?.splice(index, 1);
        }
        rule_ins.push(colType);
        setHeaderActiveAnchor(id);
      }
    } else {
      // console.log("Rule type undefined");
    }

    rldClassificObj.rule_outs = rule_outs;
    rldClassificObj.rule_outs_slash = rule_outs_slash;
    rldClassificObj.rule_ins = rule_ins;

    //setRuledClassificObj({...rldClassificObj});
    //handleRuleUpdates(rldClassificObj);
    // handleRMToggle(true);
    //headerRowLabels();
    // updateModifiedData();

    (rmToggle == false) ? setMode(true) : (setMode(false), setManualChange(true), updateModifiedData());

    if (manualchange != true) {
      updateModifiedData()
      handleRMToggle(true)
    }
  };

  const patientcellsrow = () => {
    const patientcells = [];
    var rowLabel;
    for (var j = 0; j < colOrder.length; j++) {
      let selectedColClass =
        colOrder[j] in selectedCol ? selectedCol[colOrder[j]] : "";

      if (colOrder[j] == "Rh-hr") {
        rowLabel = antigramTableConst.tableFooterDetails.PatientCells;
      } else {
        rowLabel = "";
      }
      patientcells.push(
        <td
          key={j}
          className={`pt-cell cl-${j + 1} ${colOrder[j]} ${selectedColClass}`}
        >
          {rowLabel}
        </td>
      );
    }
    return patientcells;
  };

  const handleClickState = (className) => {
    if (className.indexOf("ovl-x") != -1) {
      setActiveAnchor(0);
    } else if (className.indexOf("ovl-slash") != -1) {
      setActiveAnchor(1);
    } else if (className.indexOf("ovl-ellipse") != -1) {
      setActiveAnchor(2);
    } else {
      setActiveAnchor(null);
    }
  };

  const handleHdDropdnClickState = (className) => {
    if (className.indexOf("ruled-out-bg-slash") != -1) {
      setHeaderActiveAnchor(1);
    } else if (className.indexOf("ruled-out-bg") != -1) {
      setHeaderActiveAnchor(0);
    } else if (className.indexOf("ruled-in-bg") != -1) {
      setHeaderActiveAnchor(2);
    } else {
      setHeaderActiveAnchor(null);
    }
  };

  const [mode, setMode] = useState(false)
  const [manualchange, setManualChange] = useState(false)

  const handleChange = (id, colType, rowNumber, panelNo) => {
    const maskNewData = localApiAllData?.panels[panelNo]?.mask;
    const maskType = id == 0 ? "X" : id == 1 ? "/" : id == 2 ? "O" : "";

    const maskObj = maskNewData;
    if (colType in maskObj) {
      if (maskObj[colType][rowNumber] == maskType) {
        maskObj[colType][rowNumber] = "";
        setActiveAnchor(null);
      } else {
        maskObj[colType][rowNumber] = maskType;
        setActiveAnchor(id);
      }
    } else {
      maskObj[colType] = { [rowNumber]: maskType };
    }
    //setUpdatedMask({ ...updatedMask, maskObj });
    // handleRMToggle(true);
    (rmToggle == false) ? setMode(true) : (setMode(false), setManualChange(true), updateModifiedData());

    if (manualchange != true) {
      updateModifiedData()
      handleRMToggle(true)
    }
  };

  const colHighlight = (param, event) => {
    if (event.target === event.currentTarget) {

      if (event.ctrlKey == true) {
        if (
          param in selectedCol &&
          selectedCol[param] != undefined &&
          selectedCol[param] != ""
        ) {
          setSelectedCol({ ...selectedCol, [param]: "" });
        } else {
          setSelectedCol({ ...selectedCol, [param]: "highlight-col" });
        }
      } else {
        if (
          param in selectedCol &&
          selectedCol[param] != undefined &&
          selectedCol[param] != ""
        ) {
          setSelectedCol({ [param]: "" });
        } else {
          setSelectedCol({ [param]: "highlight-col" });
        }
      }
    }
  };

  const rowHighlight = (param, event) => {
    if (event.ctrlKey == true) {
      if (
        param in selectedRow &&
        selectedRow[param] != undefined &&
        selectedRow[param] != ""
      ) {
        setSelectedRow({ ...selectedRow, [param]: "" });
      } else {
        setSelectedRow({ ...selectedRow, [param]: "highlight-row" });
      }
    } else {
      if (
        param in selectedRow &&
        selectedRow[param] != undefined &&
        selectedRow[param] != ""
      ) {
        setSelectedRow({ [param]: "" });
      } else {
        setSelectedRow({ [param]: "highlight-row" });
      }
    }
  };

  const anitigenColsPanel = (i, panelNo) => {
    const eachCol = [];
    const getPanelData = allPanelsData && allPanelsData[panelNo];

    const maskingfun = (colType) => {
      let maskType =
        getPanelData?.mask?.[colType] && getPanelData?.mask?.[colType][i + 1];
      let mskclass;

      if (maskType == "X") {
        mskclass = "ovl-x";
      } else if (maskType == "/") {
        mskclass = "ovl-slash";
      } else if (maskType == "O") {
        mskclass = "ovl-ellipse";
      } else {
        mskclass = "";
      }
      return mskclass;
    };

    for (var j = 0; j < colOrder.length; j++) {
      let getRowClass = "pnl-" + (panelNo + 1) + "rw-" + (i + 1);
      let selectedColClass =
        colOrder[j] in selectedCol ? selectedCol[colOrder[j]] : "";
      let selectedRowClass =
        getRowClass in selectedRow ? selectedRow[getRowClass] : "";

      if (colOrder[j] == "tst-rslt") {
        eachCol.push(
          <td
            key={j}
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]
              } ${selectedColClass} ${selectedRowClass} ${maskingfun(
                colOrder[j]
              )}`}
          >
            {getPanelData?.results[i]?.value}
          </td>
        );
      } else if (colOrder[j] == "cell_number") {
        eachCol.push(
          <td
            key={j}
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]
              } ${selectedRowClass}`}
            onClick={rowHighlight.bind(this, `pnl-${panelNo + 1}rw-${i + 1}`)}
          >
            {i + 1}
          </td>
        );
      } else if (colOrder[j] == "Spc") {
        eachCol.push(
          <td
            key={j}
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]
              } ${selectedRowClass}`}
          >
            {getPanelData?.panel?.rows[i][colOrder[j]]}
          </td>
        );
      } else {
        eachCol.push(
          <MenuDropdown
            statusKey={apiAllData?.workup?.status}
            key={j}
            keyFwd={j}
            panelNo={panelNo}
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]} panel-${panelNo + 1
              } pnl-${panelNo + 1}rw-${i + 1
              } ${selectedColClass} ${selectedRowClass} ${maskingfun(
                colOrder[j]
              )}`}
            anchorEl={anchorEl}
            open={open}
            colKey={colOrder[j]}
            rowNumber={i + 1}
            // handleClick={handleClick}
            handleChange={handleChange}
            activeAnchor={activeAnchor}
            handleClearState={handleClearState}
            handleClickState={handleClickState}
          // handleClose={handleClose}
          >
            {getPanelData?.panel?.rows[i][colOrder[j]]}
          </MenuDropdown>
        );
      }
    }

    return eachCol;
  };

  const anitigenRowsPanel = (panelNo) => {
    const getPanelData = allPanelsData && allPanelsData[panelNo];
    const eachRow = [];

    for (var i = 0; i < getPanelData?.panel?.rows?.length; i++) {
      if (getPanelData?.panel?.rows[i]?.cell_number == "AC") {
        eachRow.push();
      } else {
        eachRow.push(
          <tr className={`row-${i + 1}`} key={`row-${i + 1}`}>
            {anitigenColsPanel(i, panelNo)}
          </tr>
        );
      }
    }
    eachRow.push(<tr key={panelNo}>{patientcellsrow()}</tr>);
    return eachRow;
  };

  const [accExpand, setAccExpand] = React.useState({ 0: true, 1: true });
  const updateAccState = (itm) => {
    setAccExpand({
      ...accExpand,
      [itm]: !accExpand[itm],
    });
  };

  const LoopPanels = (panelKey, key) => {
    return (
      <React.Fragment>
        <tbody className="antigram-header-data panel-a panel-a-header">
          <tr>
            {(rmToggle == false && (mode == true && manualchange == true)) ?
              (<AlertNote updateData={updateData} handleRMToggle={handleRMToggle} setMode={setMode} />) : null}
            <td className="panel-a-detail-row" colSpan={34}>
              <div className="panel-a-row">
                <span className="panel-name">
                  {antigramTableConst.panelDetails.panelName} {allPanelsData[key]?.panel?.panel_type}
                </span>
                <span className="lot-no">
                  <b>{antigramTableConst.panelDetails.lotNo}</b> {allPanelsData &&
                    allPanelsData[key]?.panel?.lot_number}
                  {allPanelsData[key]?.panel?.expiration_date && " / " + dayjs(allPanelsData[key]?.panel?.expiration_date).local().format("MM/DD/YYYY")}
                </span>
                <span className="sample-id">
                  <b>{antigramTableConst.panelDetails.sampleId}</b>{" "}
                  1234567892133
                  <b> {antigramTableConst.panelDetails.SampleDate}</b>
                  {allPanelsData[key]?.panel?.sample?.sample_date && " / " + dayjs(allPanelsData[key]?.panel?.sample?.sample_date).local().format("MM/DD/YYYY")}
                </span>
                <span className="panel-note-label">
                  {antigramTableConst.panelDetails.panelNotes}
                  <span className="notes-count">{0}</span>
                </span>
                <span className="see-details-label">
                  {antigramTableConst.panelDetails.SeeDetails}
                </span>
                <span onClick={() => updateAccState(key)}>
                  {accExpand[key] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </span>
              </div>
            </td>
          </tr>
        </tbody>

        {accExpand[key] && (
          <tbody className="antigram-main-data panel-a panel-a-table">
            {anitigenRowsPanel(key)}
          </tbody>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="table-wrapper">
      <table>
        <tbody className="antigram-header-data antigram-header-sticky">
          <tr className="table-group-heading">
            <td colSpan={3}></td>
            <td colSpan={8}>{antigramTableConst.tableHeaderGroup.RhHr}</td>
            <td colSpan={6}>{antigramTableConst.tableHeaderGroup.Kell}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Duffy}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Kidd}</td>
            <td>{antigramTableConst.tableHeaderGroup.SexLinked}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Lewis}</td>
            <td colSpan={4}>{antigramTableConst.tableHeaderGroup.MNS}</td>
            <td>{antigramTableConst.tableHeaderGroup.P}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Lutheran}</td>
            <td></td>
            <td colSpan={2}>
              {antigramTableConst.tableHeaderGroup.TestsResults}
            </td>
          </tr>

          <tr className="cell-row-header">{headerRowLabels()}</tr>
        </tbody>

        {allPanelsData &&
          allPanelsData.map((panelKey, key) => {
            return (
              <React.Fragment key={key}>
                {LoopPanels(panelKey, key)}
              </React.Fragment>
            );
          })}

        <tbody>
          <tr className="table-group-footer">
            <td colSpan={3}>
              {antigramTableConst.tableFooterDetails.ModeReactivity}
            </td>
            <td colSpan={8}>
              {antigramTableConst.tableFooterDetails.CAntiglobulin}
            </td>
            <td colSpan={11}>
              {antigramTableConst.tableFooterDetails.Antiglobulin}
            </td>
            <td colSpan={4}>
              {antigramTableConst.tableFooterDetails.Variable}
            </td>
            <td colSpan={3}>{antigramTableConst.tableFooterDetails.Cold}</td>
            <td colSpan={2}>{antigramTableConst.tableFooterDetails.Var}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default AntigramTable;
