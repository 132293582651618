import  React,{useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./index.scss";
import ClassificationRow from './ClassificationRow/ClassificationRow';
import AutoControl from '../autoControl/AutoControl';
import AntigenTyping from './antigenTyping/AntigenTyping';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({apiData,rmToggle,handleRMToggle,classificRule}) {
  const [value, setValue] = React.useState(0);
  const [ruledClassificObj, setRuledClassificObj] = React.useState();
  useEffect(()=>{
    setRuledClassificObj(classificRule);
  },[classificRule]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

  };

  const classificationRow = () => {
    const colOrder = ["D", "C", "E", "c", "e", "f", "Cw", "V", "K", "k", "Kpa", "Kpb", "Jsa", "Jsb", "Fya", "Fyb", "Jka", "Jkb", "Xga", "Lea", "Leb", "S", "s", "M", "N", "P1", "Lua", "Lub"];
    const classificationCells = [];

    let ruleOuts;
    let ruleOutsSlash;
    let ruleIn;

    ruleOuts = ruledClassificObj?.rule_outs;
    ruleOutsSlash = ruledClassificObj?.rule_outs_slash;
    ruleIn = ruledClassificObj?.rule_ins;

    let ruleClass;
    //let counts = 0;
    let couterLabel;
    for(var j=0; j<colOrder.length; j++){
      if(ruleOuts && ruleOuts.length>0 && ruleOuts.indexOf(colOrder[j]) > -1) {
        ruleClass = "classific-ruled-out-bg";
        // counts = counts++;
        // couterLabel = <span className='count'>{counts}+</span>;
      }else if(ruleOutsSlash && ruleOutsSlash.length>0 && ruleOutsSlash.indexOf(colOrder[j]) > -1) {
        ruleClass = "classific-ruled-out-bg";
      }else if(ruleIn && ruleIn.length>0 && ruleIn.indexOf(colOrder[j]) > -1) {
        ruleClass = "classific-ruled-in-bg";
      }else {
        ruleClass = "";
        couterLabel = "";
      }
      classificationCells.push(<div key={j} className='classfic-wrapper'><span className={`classfic-cell cl-${j+1} ${colOrder[j]} ${ruleClass}`}>{colOrder[j]}</span>{couterLabel}</div>);
    }
    return classificationCells;
}

  //Toggle button code
  const [slectedView, setSlectedView] = React.useState("recommended");

  useEffect(()=>{
    rmToggle && setSlectedView("manual");
  },[rmToggle])

  const handleToggleChange = (event, viewMode) => {
    if(viewMode !== null && viewMode=="recommended"){
      setSlectedView(viewMode);
      handleRMToggle(false);
    }else if(viewMode !== null && viewMode=="manual"){
      setSlectedView(viewMode);
      handleRMToggle(true);
    }
  };

  //Rules dropdown button code
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ruleType, setRuleType] = React.useState("3 x 3");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const updateRules = (ruleTp) => {
    setRuleType(ruleTp);
    handleClose();
  };

  //Accordion state change
  const [accOpen, setAccOpen] = React.useState(true);

  return (
    <div className='cust-box-wrapper'>
      <div className='tab-row-wrapper'>
        <div className='option-sec'>
          <span className='view-label'>View</span>
          <span className='toggle-btn'>
            <ToggleButtonGroup
              value={slectedView}
              exclusive
              onChange={handleToggleChange}
              aria-label="Platform"
            >
              <ToggleButton className='btn-rec' value="recommended">RECOMMENDED <ErrorOutlineIcon /></ToggleButton>
              <ToggleButton className='btn-manual' value="manual">MANUAL</ToggleButton>
            </ToggleButtonGroup>
          </span>

          <span className='rules-btn'>
            <Button
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="outlined"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Rules <span className='rule-badge'>{ruleType}</span>
            </Button>
            <StyledMenu id="demo-customized-menu" className="rule-dropdown" anchorEl={anchorEl} open={open} onClose={handleClose}>
              <li className={ruleType == "3 x 3" ? `active dropdn-list`: "dropdn-list"} onClick={()=>updateRules("3 x 3")}> 3 X 3 {ruleType == "3 x 3" && <span><CheckIcon /></span>}</li>
              <li className={ruleType == "2 x 2" ? `active dropdn-list`: "dropdn-list"}  onClick={()=>updateRules("2 x 2")}> 2 X 2 {ruleType == "2 x 2" && <span><CheckIcon /></span>}</li>
              <button className='btn-outline'>View Rules</button>
              <button className='btn-solid'>Update Recommendations</button>
            </StyledMenu>
          </span>
        </div>
        <Tabs className='tabs-group' value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Classifications" {...a11yProps(0)} />
          <Tab label="Antigen typing" icon={true && <CheckIcon />} iconPosition="end" {...a11yProps(1)} />
          <Tab label="Autocontrol" {...a11yProps(2)} />
          <Tab label="DAT" {...a11yProps(3)} />
        </Tabs>
        <AccordionSummary onClick={()=>{setAccOpen(!accOpen)}}
          expandIcon={!accOpen && <ExpandMoreIcon /> || accOpen && <ExpandLessIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
        ></AccordionSummary>
      </div>

      {accOpen && <Accordion>
        <AccordionDetails>
          <TabPanel value={value} index={0}>
            <ClassificationRow ruledClassificObj={ruledClassificObj} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className='antigen-typing-tab'>
              <div className='values-row'>
                <AntigenTyping />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AutoControl/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className='dat-tab'>
              <div className='label-row'>
                <p>DAT-Title</p>
              </div>
              <div className='values-row'>
                <p>DAT-Details</p>
              </div>
            </div>
          </TabPanel>
        </AccordionDetails>
      </Accordion>}

    </div>
  );
}
