import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import "./TestTable.scss";
import dayjs from "dayjs";
import Filter from "../filter/Filter";
import MenuSelect from "./MenuSelect";
import SearchFilter from "../filter/SearchFilter";
import Stack from "@mui/material/Stack";
import Constants from "../../languages/en.json";
import { ColumnSort } from "../columnSort/ColumnSort";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation, useParams } from "react-router-dom";
import PositionedSnackbar from "./PositionedSnackbar";
import { NoResultsFound } from "../noResultsFound/NoResultsFound";
import { ServerError } from "../serverError/ServerError";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const TestTable = ({
  posts,
  loading,
  onSortChanged,
  sortParam,
  filters,
  updateFilters,
  filterValues,
  showLaboratory,
  searchText,
  onSearchTextChange,
  addTestResults,
  serverError,
  onAddToWorkup,
}) => {
  const location = useLocation();

  const { isAddToWorkupFlow } = location.state || {};

  // If Data not Loaded //
  const { workupId } = useParams();
  console.log("Test Table", isAddToWorkupFlow, workupId);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [searchValue, setSearchValue] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [searchResults, setsearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const searchData = APIData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setsearchResults(searchData);
    } else {
      setsearchResults(APIData);
    }
  };

  if (loading) {
    return <h2>Loading...</h2>;
  }

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };
  const [selected, setSelected] = useState([]);
  const [testResultData, setTestResultData] = useState([]);
  // const [dataArr, setDataArr] = useState([]);
  var testArray = [];
  // useEffect(() => {
  //   testArray=testResultData;
  //   console.log(testResultData, "updatedArr",testArray);
  //   // setTestResultData(testResultData);
  //   // console.log( updatedArr,"updatedArr");
  // }, [testResultData]);

  var updatedArr = [];
  var arraynew;

  if (arraynew && arraynew == "undefined") {
    arraynew = ["1"];
  }
  const handlePanelChange = async (val, id) => {
    const dataArray = [];
    const index = selected.indexOf(id);
    if (index == -1) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((sel) => sel != id));
    }
    // dataArray.push(val);
    //  updatedArr=[...testResultData,val]
    arraynew?.push(val);
    var newUpdtArr = [...testResultData, val];
    setTestResultData((testResultData) => newUpdtArr);
    // console.log(arraynew, testResultData, "testResultData====", val);
    const newData = JSON.stringify(
      newUpdtArr?.length > 1 ? newUpdtArr : newUpdtArr
    );
    // console.log(newData,"newData")
    newData && localStorage.setItem("allTestResultsData", newData);
    // setSelected(id);
  };
  // console.log(testResultData?.length, "dataArrout", testResultData);

  const handleAddTestResults = () => {};

  const _testTableHeaders = Constants.en.testTable.testTableHeader;

  const getTable = () => {
    return (
      <div className="test-table-root">
        <Box>
          <TableContainer className="table-container">
            <Table className="table" arai-label="simple">
              <TableHead>
                <TableRow>
                  {addTestResults && (
                    <TableCell className="table-header-cell"></TableCell>
                  )}
                  <TableCell className="table-header-cell">
                    {_testTableHeaders.sample_id}
                  </TableCell>
                  <TableCell className="table-header-cell">
                    <span className="table-col-heading-wrapeer">
                      <span className="table-col-heading">
                        {_testTableHeaders.patient_name}
                      </span>
                      <span className="table-sorting-icons">
                        <ColumnSort
                          fieldName="patient_name"
                          sortDirection={sortParam["patient_name"]}
                          onSortChanged={onSortChanged}
                        />
                      </span>
                    </span>
                  </TableCell>

                  <TableCell className="table-header-cell">
                    {_testTableHeaders.patient_id}
                  </TableCell>

                  <TableCell className="table-header-cell">
                    {_testTableHeaders.lot}
                  </TableCell>

                  <TableCell className="table-header-cell">
                    <span className="table-col-heading-wrapeer">
                      <span className="table-col-heading">
                        {_testTableHeaders.result_date}
                      </span>
                      <span className="table-sorting-icons">
                        <ColumnSort
                          fieldName="result_dt"
                          sortDirection={sortParam["result_dt"]}
                          onSortChanged={onSortChanged}
                        />
                      </span>
                    </span>
                  </TableCell>

                  {showLaboratory && (
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">
                          {_testTableHeaders.lab}
                        </span>
                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="laboratory"
                            sortDirection={sortParam["laboratory"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>
                  )}
                  <TableCell className="table-header-cell">
                    <span className="table-col-heading-wrapeer">
                      <span className="table-col-heading">
                        {_testTableHeaders.panel_type}
                      </span>
                      <span className="table-sorting-icons">
                        <ColumnSort
                          fieldName="panel_type"
                          sortDirection={sortParam["panel_type"]}
                          onSortChanged={onSortChanged}
                        />
                      </span>
                    </span>
                  </TableCell>
                  <TableCell className="table-header-cell">
                    <span className="table-col-heading-wrapeer">
                      <span className="table-col-heading">
                        {_testTableHeaders.workup}
                      </span>
                      <span className="table-sorting-icons">
                        <ColumnSort
                          fieldName="workup_name"
                          sortDirection={sortParam["workup_name"]}
                          onSortChanged={onSortChanged}
                        />
                      </span>
                    </span>
                  </TableCell>

                  <TableCell className="table-header-cell">
                    <span className="table-col-heading-wrapeer">
                      <span className="table-col-heading">
                        {_testTableHeaders.status}
                      </span>
                      <span className="table-sorting-icons">
                        <ColumnSort
                          fieldName="status"
                          sortDirection={sortParam["status"]}
                          onSortChanged={onSortChanged}
                        />
                      </span>
                    </span>
                  </TableCell>

                  <TableCell className="table-header-cell"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posts &&
                  posts.map((user, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(index)}
                      className={index === selectedRowIndex ? "selected" : null}
                    >
                      {addTestResults && (
                        <TableCell>
                          <FormControlLabel
                            label={""}
                            control={
                              <Checkbox
                                multiple
                                checked={selected.includes(user?.sample_id)}
                                onChange={() =>
                                  handlePanelChange(user, user?.sample_id)
                                }
                                sx={{
                                  [`&, &.Mui-checked`]: {
                                    color: "#CF2F44",
                                  },
                                }}
                              />
                            }
                            className="test-item"
                            key={index}
                          />
                        </TableCell>
                      )}
                      <TableCell>{user.sample_id}</TableCell>
                      <TableCell>
                        {user.patient_name === null ||
                        user.patient_name === undefined ? (
                          <Button id="add-button" variant="outlined">
                            +ADD
                          </Button>
                        ) : (
                          <span>{user?.patient_name}</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {user.patient_id === null ||
                        user.patient_id === undefined ? (
                          <Button id="add-button" variant="outlined">
                            +ADD
                          </Button>
                        ) : (
                          <span>{user?.patient_id}</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {user.lot_number === null || undefined ? (
                          "-"
                        ) : (
                          <span>{user.lot_number}</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {user.result_dt === null ||
                        user.result_dt === undefined ? (
                          "-"
                        ) : (
                          <span>
                            {dayjs(user.result_dt)
                              .local()
                              .format("MM/DD/YYYY - HH:MM")}
                          </span>
                        )}
                      </TableCell>
                      {showLaboratory && (
                        <TableCell>
                          {user.laboratory === null ||
                          user.laboratory === undefined ? (
                            "-"
                          ) : (
                            <span>{user?.laboratory}</span>
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        {user.panel_type === null ||
                        user.panel_type === undefined ? (
                          "-"
                        ) : (
                          <span>{user?.panel_type}</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {user.workup_name === null ||
                        user.workup_name === undefined ? (
                          <Button
                            id="add-button"
                            variant="outlined"
                            onClick={() => onAddToWorkup(user)}
                            
                          >
                            +ADD
                          </Button>
                        ) : (
                          <Link to="#">{user.workup_name}</Link>
                        )}
                      </TableCell>

                      <TableCell>
                        <span
                          className="status"
                          style={{
                            backgroundColor:
                              (user?.status?.toLowerCase() === "new"
                                ? "#D0E7FE"
                                : null) ||
                              (user?.status?.toLowerCase() === "in progress"
                                ? "#F4CF71"
                                : null) ||
                              (user?.status?.toLowerCase() === "in review"
                                ? " #ED8B00"
                                : null) ||
                              (user?.status?.toLowerCase() === "completed"
                                ? "#A4EDB0"
                                : null),
                          }}
                        >
                          {user.status}
                        </span>
                      </TableCell>
                      <TableCell>
                        <MenuSelect
                          panelId={posts[selectedRowIndex]?.id}
                          onAddToWorkup={() => onAddToWorkup(user)}
                          date={posts[selectedRowIndex]?.result_dt}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {addTestResults && (
          <div className="button-container">
            <Stack direction="row">
              <Link
                className="cancel"
                to={workupId ? `/single-workup/${workupId}` : "/single-workup"}
                state={
                  workupId
                    ? { editWorkupData: true, testResultData: [] }
                    : { testResultData: [] }
                }
                variant="outlined"
                onClick={() => {
                  localStorage.removeItem("allTestResultsData");
                }}
              >
                Cancel
              </Link>
            </Stack>
            <Stack direction="row">
              <Link
                className="submit"
                to={workupId ? `/single-workup/${workupId}` : "/single-workup"}
                state={
                  workupId
                    ? { editWorkupData: true, testResultData: testArray }
                    : { testResultData: testArray, isAddToWorkupFlow }
                }
                // onClick={handleAddTestResults}
                variant="contained"
                type="button"
              >
                Add test results
              </Link>
            </Stack>
          </div>
        )}
      </div>
    );
  };

  const getComponent = () => {
    if (serverError) {
      return <ServerError />;
    }

    if (posts.length == 0) {
      return <NoResultsFound></NoResultsFound>;
    }

    return getTable();
  };

  return (
    <>
      <div>
        <h2 className="page-title">
          {addTestResults && addTestResults ? "Create Workup" : "Tests"}
        </h2>
      </div>
      {addTestResults && (
        <div className="create-workup-header-testtable">
          <b>Add test results</b>
          <p>Add test results to this workup by searching below.</p>
        </div>
      )}
      <div className="filter-root">
        <div className="left-sec">
          <SearchFilter
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
          />
        </div>
        <div className="filter-right-sec">
          <Filter
            updateFilters={updateFilters}
            filters={filters}
            filterValues={filterValues}
          />
        </div>
      </div>

      {getComponent()}
    </>
  );
};

export default TestTable;
