import Api from "../../utils/apiConfig";

export const getResults = async () => {

    const response = await Api.get(
      "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/%7BorgScope%7D/results",
      true
    );
    if (response) {
  
      return response?.data;
      
    }
};
export const getSingleResult = async (panelId) => {

  const response = await Api.get(
    "https://ciy29gd3ye.execute-api.us-east-1.amazonaws.com/dev/v1/%7BorgScope%7D/results/"+panelId,
    true
  );
  if (response) {

    return response?.data;
    
  }
};