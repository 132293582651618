import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";

function WorkupMenu({ workupId, status }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      linkName: `/single-workup/${workupId}`,
      linkText: "Edit workup",
      status: status == "COMPLETED" ? true : false,
      state: {
        testResultData: [],
        addPatientData: [],
        editWorkupData: true,
        id: workupId,
      },
    },
    {
      linkName: "#",
      linkText: "Download",
      state: {},
      status: status == "COMPLETED" ? true : false,
    },
  ];
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon className="more-icon" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === "Pyxis"}>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              className="workup-menu-item"
              to={option?.status == true ? "#" : option?.linkName}
              state={option?.state}
            >
              {option?.linkText}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default WorkupMenu;
