import React from "react";
import PositionedSnackbar from "../testTable/PositionedSnackbar";
import {ReactComponent as Search} from "../../assets/images/Search.svg"

export const NoResultsFound=()=>{
    return (
        <div className="no-result-found">         
          <div><PositionedSnackbar/></div>
          <div className="circle"><Search></Search></div>
          <h3>No Results found</h3>
          <p>
            Try adjusting your search or filter to find what you’re looking for.
          </p>
        </div>
    );
}