import React from 'react';
import { CrossMask, GreenTick } from "../../utils/icons.js";
import "./index.scss";

export const SuccessPopup = ({ children, onClose }) => {
  return (
    <>
      <div className="success-popup">
        <GreenTick width={25} height={25} />
        {children}
        <div style={{ cursor: "pointer" }} onClick={onClose}>
          <CrossMask width={15} height={15} />
        </div>
      </div>
    </>
  );
};
