import React, { useEffect, useState } from "react";
import ModalComponent from "../../../modal/Modal";
import Button from "@mui/material/Button";
import TuneIcon from "@mui/icons-material/Tune";
import { OutlinedInput, MenuItem, FormControl, Select } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
// import "../../../../components/filter/index.scss";
import "./index.scss"
import Constants from "../../../../languages/en.json";

const _initialStatusFilters = Constants.en.workupFilters.initialStatusFilters;
const _filterHeaders = Constants.en.filters.filterHeaders;

const WorkupFilters = ({ updateFilters, filters, filterValues }) => {
    const [filterCount, setFilterCount] = useState(null);
  const [statusFilters, setStatusFilters] = useState(_initialStatusFilters);
  const [applyStatus,setApplyStatus] = useState(false)
  const [selectedLastUpdatedBy, setSelectedLastUpdatedBy] = useState([]);

  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
    error: null,
  });

  useEffect(() => {
    applyFilters(filters);
  }, [filters]);

  const applyFilters = (filters) => {
    if (filters.statuses) {
      setStatusFilters(
        statusFilters.map((s) => ({
          ...s,
          isSelected: filters.statuses.includes(s.value),
        }))
      );
    }

    setDates({
      ...dates,
      startDate: filters.startDate || null,
      endDate: filters.endDate || null,
    });

    if (filters.lastUpdatedBy) {
      setSelectedLastUpdatedBy([...filters.lastUpdatedBy]);
    }    
  };

  const handleLastUpdatedByChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedLastUpdatedBy(value);
  };

  const handleDateChange = (date, type) => {
    const newDates = { ...dates, [type]: date };
    const { startDate, endDate } = newDates;
    setDates(newDates);
  };

  const handleStatusChanged = (value) => {
    const index = statusFilters.findIndex((s) => s.value == value);
    var item = statusFilters[index];

    var newStatusFilters = [...statusFilters];
    newStatusFilters[index] = { ...item, isSelected: !item.isSelected };
    setStatusFilters(newStatusFilters);
  };

  

  const handleClear = () => {
    setStatusFilters(_initialStatusFilters);
    setDates({ startDate: null, endDate: null });
    setSelectedLastUpdatedBy([]);

    updateFilters({});
  };

  const getFiltersToApply = () => {
    const filters = {
      statuses: statusFilters.filter((s) => s.isSelected).map((s) => s.value),
      startDate: dates.startDate,
      endDate: dates.endDate,
      lastUpdatedBy: selectedLastUpdatedBy,
      
      filterCount,
    };

    return filters;
  };

  const handleApply = () => {
    const filtersToApply = getFiltersToApply();
    updateFilters(filtersToApply);
    handleClose();
    setApplyStatus(true)
  };

  useEffect(() => {
    let filterCount = statusFilters.filter((s) => s.isSelected).length;
    if (dates.startDate != null) filterCount++;
    filterCount += selectedLastUpdatedBy.length > 0 ? 1 : 0;

    if (filterCount === 0) {
      filterCount = null;
    }

    setFilterCount(filterCount);
  }, [statusFilters, dates, selectedLastUpdatedBy]);

  // Rules dropdown button code
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isFilterOpen = anchorEl != null;
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    if(showFilterCount == null){
      setApplyStatus(false)
  
      }

    
  };
  const handleClose = () => {
    document.getElementById("demo-customized-button").style.backgroundColor = null;
    setAnchorEl(null);
  };

  const showFilterCount = filters.filterCount && filters.filterCount > 0;

  const displayCount = (
    <span className="filter-count">
      {showFilterCount ? filters.filterCount : ""}
    </span>
  );
  const disableButton = (filterCount==null && applyStatus==false?true:false);
  const clearDisable = (filterCount==null ?true:false);

  return (
    <div className="filter-right-sec">
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        startIcon={showFilterCount ? displayCount : <TuneIcon />}
        style={{ backgroundColor: isFilterOpen ? "#FDEBF0" : undefined }}
      >
        {_filterHeaders.filter_button}
      </Button>

      <ModalComponent
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open1}
        onClose={handleClose}
        className="filter-custom-modal"
      >
        <div className="filter-wrapper">
          <span className="filter-label">
            {_filterHeaders.filter_count}
            {filterCount && <span> ({filterCount})</span>}
          </span>
          <button style={{fontFamily:"Rubik"}} className={filterCount==null?"disable-clear-label":"clear-label"} onClick={handleClear} disabled={disableButton}>
            {_filterHeaders.clear_button}
          </button>
          <button style={{fontFamily:"Rubik"}} className={filterCount==null && applyStatus==false?"disable-filter-lable":"apply-filter-lable"} onClick={handleApply} disabled={disableButton}>
            {_filterHeaders.apply_button}
          </button>
        </div>
        {/* /* Status Filter */}

        <div className="filter-status-wrapper">
          <div className="status-label">{_filterHeaders.status}</div>
          <div className="status-filter-labels-wrapper">
            {statusFilters.map((s) => (
              <span
                className={
                  s.isSelected
                    ? "status-" + s.value.replace(" ", "-")
                    : undefined
                }
                key={s.value}
                onClick={() => handleStatusChanged(s.value)}
              >
                {s.display}
              </span>
            ))}
          </div>{" "}
        </div>

        {/*Last Updated Dates  */}

        <div className="filter-date-wrapper">
          <div className="date-label">{_filterHeaders.last_updated_date}</div>
          <div className="date-picker-label">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={_filterHeaders.start_date}
                value={dates.startDate}
                onChange={(newValue) => {
                  handleDateChange(newValue, "startDate");
                }}
                className="date-container"
                renderInput={(params) => <TextField {...params} />
              }
              />

              <DatePicker
                label={_filterHeaders.end_date}
                className="date-container"
                value={dates.endDate}
                onChange={(newValue) => {
                  handleDateChange(newValue, "endDate");
                }}
                renderInput={(params) => <TextField {...params} />}
                minDate={dates.startDate}
              />
            </LocalizationProvider>
          </div>
          {dates.error && <div className="date-error-label">{dates.error}</div>}
        </div>

        {/* Last Updated By */}
        {/* {filterValues.length > 1 && ( */}
          <div className="filter-lab-wrapper">
            <div className="lab-label">{_filterHeaders.last_updated_by}</div>

            <FormControl className="lab-select-wrapper">
              <Select
                multiple
                displayEmpty
                value={selectedLastUpdatedBy}
                onChange={handleLastUpdatedByChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected && selected.length === 0) {
                    return <em>{_filterHeaders.user_select}</em>;
                  }
                  return (selected || []).join(", ") + ",";
                }}
              >
                {filterValues.lastUpdatedBy.map(item => {
                  const isSelected = selectedLastUpdatedBy.includes(item);

                  return (
                    <MenuItem key={item} value={item} className="lab-menu-item">
                      <div className="lab-item">
                        <div
                          className={`lab-item-text ${
                            isSelected ? "selected" : undefined
                          }`}
                        >
                          {item}
                        </div>
                        {isSelected && <DoneIcon></DoneIcon>}
                      </div>
                    </MenuItem>
                  );
                }
                )}
              </Select>
            </FormControl>
          </div>
        {/* )} */}

       
      </ModalComponent>
    </div>
  )
}

export default WorkupFilters