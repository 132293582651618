import * as React from 'react';
import {Box  } from "@mui/material";
  import {Pagination} from '@mui/material';




export default function PatientPagination({
    onChange,
    postsPerPage,
    totalPosts,
    startIndex,
    lastIndex,
  }) {
    var text="";

    if(totalPosts==0){
      text="Showing 0 of 0 results"
    }
    else{
    text = `Showing ${startIndex + 1} to ${
      lastIndex > totalPosts ? totalPosts : lastIndex
    } results of ${totalPosts} results`;
    }

  return (
    <Box className="box-wrapper">
      <span>{text}</span>

      <Pagination
        count={Math.ceil(totalPosts / postsPerPage)}
        onChange={onChange}
        siblingCount={0}
        boundaryCount={1}
      />
    </Box>
  );
}
