import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './index.scss'

const AlertDialogModal = (props) => {
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        {props?.dialogButtonName}
      </Button> */}
      <Dialog
        // className='alert-dialog-modal'
        sx={props.sx}
        open={props?.open}
        // className="dialog-box"
        className={props.className ? `${props.className} dialog-box` :"dialog-box"}
        onClose={props?.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // className={props.className}
      >
        <DialogTitle className="alert-dialog-title">{props?.dialogTitle}
        <span><CloseOutlinedIcon className="close-icon" onClick={props?.handleClose}/></span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="alert-dialog-description">
            {props?.dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="button"
            className="alert-cancel"
            onClick={props?.handleClose}
            autoFocus
            variant="outlined"
            // sx={{ borderRadius: "5px", color:"#CF2F44" }}
          >
            {props?.btnNoText}
          </Button>
          <Button
            id="button"
            className="alert-submit"
            onClick={props?.handleProceed}
            variant="contained"
            // sx={{ background: "#CF2F44", borderRadius: "5px", color: "white" }}
          >
            {props?.btnYesText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AlertDialogModal;
