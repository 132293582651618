import React, { Fragment, useState, useEffect } from "react";
import HomePageLayout from "./views/homePageLayout/HomePageLayout";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Fragment>
        <HomePageLayout>
          <Component />
        </HomePageLayout>
    </Fragment>
  );
};

export default ProtectedRoute;
