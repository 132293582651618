import { flexbox } from "@mui/system";
import React, { useState } from "react";
import AlertDialogModal from "../../alertDialogModal/AlertDialogModal";
import ClassificationRow from "../antigramHeader/ClassificationRow/ClassificationRow";
const SubmitModal = (props) => {
  const Styles={
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "100%",
        height: "500px",
        borderRadius: "8px",
        paddingTop: "5px",
        // left: "30px",
        padding: "30px",
      },
      "& .MuiDialogTitle-root": {
        padding: "0px",
      },
      "& .MuiDialogContent-root ": {
        padding: "0px",
        "& .MuiTypography-root": {
          "& .recommended-manual-container": {
            background: "#E9F0F3",
            display:"flex",
            width:"100%",
            padding: "10px 20px 20px 16px",
            borderRadius: "8px",
            // margin:"5px",
            flexGrow: 0,
            "& .classification-tab": {
              paddingLeft: "25px",
              width:"100%",
            },
          },
        },
      },
    },
  }
  const selectedAnalysis = localStorage.getItem("selectedAnalysis");
  const selectedAnalysisData = JSON.parse(selectedAnalysis);
  const submitModalContent = () => {
    return (
      <div className="recommended-manual-container">
        <ClassificationRow ruledClassificObj={selectedAnalysisData} />
      </div>
    );
  };
  const dualogTitleContent = () => {
    return (
      <div className="dialog-content">
        Submit {localStorage.getItem("selectedAnalysisKey")} analysis?
        <span style={{
          fontSize:"14px",
          paddingLeft:"5px",
          fontWeight:"400",
          color:"#000000"
        }}>(Step 2 of 2)</span>
        <p
          style={{
            width: "413px",
            height: " 22px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "22px",
            color: " #000000",
          }}
        >
          You will not be able to make any edits after you submit.
        </p>
      </div>
    );
  };

  return (
    <AlertDialogModal
      sx={Styles}
      dialogButtonName="test"
      dialogTitle={dualogTitleContent()}
      dialogContent={submitModalContent()}
      btnYesText="Submit"
      btnNoText="Cancel"
      open={props?.open}
      handleClickOpen={props?.handleClickOpen}
      handleClose={props?.handleClose}
      handleProceed={props?.handleSubmitWorkupData}
    />
  );
};
export default SubmitModal;
