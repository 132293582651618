import React, { useState } from "react";
import { Link } from "react-router-dom";
import TestTubeEmpty from "../../assets/images/Test-tube-empty.js";
import Person from "../../assets/images/Person.js";
import FileOutline from "../../assets/images/File-outline.js";
import Folder from "../../assets/images/Folder.js";
import "./index.scss";

const Sidebar = () => {
  const [activeId, setActiveId] = useState(1);

  const sideBarItems = [
    {
      id: 1,
      icon: "testTube",
      linkTitle: "Test",
      linkName: "/",
      state: { addTestResults: false },
    },
    {
      id: 2,
      icon: "folder",
      linkTitle: "Workups",
      linkName: "/workup-home",
      state: {},
    },
    {
      id: 3,
      icon: "person",
      linkTitle: "Patients",
      linkName: "/patient-home",
      state: { addPatientDetails: false },
    },
    // { id: 4, icon: "fileOutline", linkTitle: "Library",linkName:"/"  },
  ];

  return (
    <div className="sidebar-root">
      {sideBarItems &&
        sideBarItems?.map((item, index) => {
          return (
            <div
              onClick={() => {
                setActiveId(item?.id);
              }}
              key={index}
              className="side-nav-item"
            >
              <Link
                className={item.id == activeId ? "active" : " "}
                to={item.linkName}
                state={item?.state}
              >
                {item.icon === "testTube" && (
                  <TestTubeEmpty
                    fill={item.id == activeId ? "#CF2F44" : " #34383C"}
                  />
                )}
                {item.icon === "folder" && (
                  <Folder fill={item.id == activeId ? "#CF2F44" : " #34383C"} />
                )}
                {item.icon === "person" && (
                  <Person fill={item.id == activeId ? "#CF2F44" : " #34383C"} />
                )}
                {item.icon === "fileOutline" && (
                  <FileOutline
                    fill={item.id == activeId ? "#CF2F44" : " #34383C"}
                  />
                )}
                <span>{item.linkTitle}</span>
              </Link>
            </div>
          );
        })}
    </div>
  );
};
export default Sidebar;
