import React from "react";
import dayjs from "dayjs";
import "./index.scss";
import Constants from "../../../../languages/en.json";

export const WorkupTestDetails = ({testResult}) => {
 

  console.log("Test details", testResult);

  const _testTableHeaders = Constants.en.testTable.testTableHeader;
  return (
    <div className="workup-test-detail-container">
      <h2 className="add-workup-title">Add to Workup</h2>
      <div className="test-info">
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.sample_id}</label>
          <div className="test-data">
            {testResult && testResult?.sample_id?.substring(0, 25)}
          </div>
        </div>
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.lot}</label>
          <div className="test-data">{testResult?.lot_number}</div>
        </div>
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.result_date}</label>
          <div className="test-data">
            {dayjs(testResult?.result_dt).local().format("MM/DD/YYYY-HH:MM ")}
          </div>
        </div>
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.lab}</label>
          <div className="test-data">{testResult?.laboratory}</div>
        </div>
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.panel_type}</label>
          <div className="panel-type">{testResult?.panel_type}</div>
        </div>
        </div>
        <div className="add-workup-information">
          <h4>Workups</h4>
          <p>Assign the test result to an existing workup(s) by selecting it below or add to a new workup by clicking “Create workup”</p>
        </div>
        </div> 
    
  );
};