//import * as React from "react";
import React, {useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SlashMask, CrossMask, EllipseMask } from "../../../utils/icons";
import DpDownImgDark from "../../../assets/images/HeaderDropdownIconDark.svg"
import DpDownImgLight from "../../../assets/images/HeaderDropdownIconLight.svg"
import "./index.scss";

const HeaderMenuDropDown = ({
  children,
  updateHeaderRules,
  colHighlight,
  headerActiveAnchor = null,
  colKey,
  keyFwd,
  className,
  handleHdDropdnClearState,
  handleHdDropdnClickState,
  statusKey
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [transformPosition,setTransformPosition] = React.useState("top");
  const [anchorPosition,setAnchorPosition] = React.useState("bottom");
  const MENU_HEIGHT=130;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if(statusKey === "COMPLETED"){
      return false
    }
    //console.log("Node", event.currentTarget.parentNode);
    event.preventDefault();
    event.stopPropagation();
    //event.stopImmediatePropagation();
    //debugger; // eslint-disable-line no-debugger
    if(event.clientY+MENU_HEIGHT >= window.innerHeight){ 
      setTransformPosition("bottom");
      setAnchorPosition("top");
    }
    else{
      setTransformPosition("top");
      setAnchorPosition("bottom");
    }
    handleHdDropdnClickState(className);
    setAnchorEl(event.currentTarget.parentNode);
    if(anchorEl != null){
      handleClose();
    }
  };
  
  const handleClose = (id) => {
    setAnchorEl(null);
    handleHdDropdnClearState(null);
  };

  const manuItems = [
    {
      iconSrc: "CrossMask",
    },
    {
      iconSrc: "SlashMask",
    },
    {
      iconSrc: "EllipseMask",
    },
  ];

  return (
    <React.Fragment>
      <td
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{}}
        className={open ? `${className} dropdwon-open ` : className}
        onClick={statusKey === "COMPLETED" ? null :(event)=>{colHighlight(event, colKey)}}
        key={keyFwd}
      >
        {children}

        {(className.indexOf("Rh-hr") == -1 && className.indexOf("donor_number") == -1 && className.indexOf("tst-rslt") == -1) &&
        <span className="headerDropdown" onClick={(event)=>{handleClick(event)}}>
          {className.indexOf("highlight-col") == -1 ? (statusKey === "COMPLETED") ? "" : <img src={DpDownImgDark} /> : <img src={DpDownImgLight} />}
        </span>}
      </td>
      <Menu
        className="MuiMenu-root manual-markup-modal"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        elevation={0}
        anchorOrigin={{
          vertical: anchorPosition,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: transformPosition,
          horizontal: "center",
        }}
      >
        {manuItems &&
          manuItems.map((icon, idx) => {
            return (
              <MenuItem
                key={idx}
                onClick={() => updateHeaderRules(idx, colKey, handleClose())}
                className={headerActiveAnchor == idx ? `${idx} active` : `${idx} `}
              >
                {icon?.iconSrc == "CrossMask" ? (
                  <CrossMask fillColor={headerActiveAnchor == idx && "white"} />
                ) : icon?.iconSrc == "SlashMask" ? (
                  <SlashMask fillColor={headerActiveAnchor == idx && "white"} />
                ) : icon?.iconSrc == "EllipseMask" ? (
                  <EllipseMask fillColor={headerActiveAnchor == idx && "white"} />
                ) : (
                  ""
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </React.Fragment>
  );
};
export default HeaderMenuDropDown;
