import React, { useState, useContext } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { userData } from "./apiData";
import Sidebar from "../../components/sidebar/Sidebar";
import Filter from "../../components/filter/Filter";
import "./index.scss";
import { userDetails } from "../patientData/Patients";

import { SpinnerContext } from "../../context/spinner-context";
import dayjs from "dayjs";

const HomePageLayout = (props) => {
  const [allData, setData] = useState(userDetails);
  const { showSpinner } = useContext(SpinnerContext);

  const generateLabDataForDropdown = () => {
    return [...new Set(userDetails.map((item) => item.lab))];
  };

  const generateWorkupData = () => {
    return [...new Set(userDetails.map((item) => item.Workup_name))];
  };

  // Laboratory Filter //
  const handleFilterLab = (lab) => {
    const filteredData = userDetails.filter((item) => {
      if (item.lab === lab) {
        return item;
      }
    });

    setData(filteredData);
  };

  // Workup Filter //
  const handleFilterWorkup = (Workup_name) => {
    const filteredData = userDetails.filter((item) => {
      if (item.Workup_name === Workup_name) {
        return item;
      }
    });

    setData(filteredData);
  };

  // Status Filter //
  const handleFilterStatus = (Status) => {
    const filteredData = userDetails.filter((item) => {
      if (item.Status === Status) {
        return item;
      }
    });

    setData(filteredData);
  };

  // Date Filter  //
  const handleFilterDate = (Date, field) => {
    const filteredData = userDetails.filter((item) => {
      if (field === "from" && dayjs(item.Date).isSameOrAfter(dayjs(Date))) {
        return item;
      } else if (
        field === "to" &&
        dayjs(item.Date).isBeforeOrSame(dayjs(Date))
      ) {
        return item;
      }
    });

    setData(filteredData);
  };
  return (
    <div className="homepage-layout-root">
      <div className="homapage-header">
        <Header userData={userData} />
      </div>

      <div className="homepage-main-wrapper">
        <div className="homepage-sidebar">
          <Sidebar />
        </div>

        <div
          className="homepage-right-block-wrapper"
          style={{ visibility: showSpinner ? "hidden" : "visible" }}
        >
          <div className="homepage-content-wrapper">
            <div className="homepage-child-components">{props.children}</div>
          </div>

          <div className="homepage-footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePageLayout;
