import React from 'react'
import {Button} from "@mui/material"
import '../components/testTable/TestTable.scss'

export const nameFormatter = (firstName, lastName) => {
  // if (!firstName && !lastName) {
  //   return <button id="add-button"  variant="outlined">+ADD</button>;
  // }

  if (!lastName) {
    return firstName;
  }
  if (!firstName) {
    return lastName;
  }

  return `${lastName}, ${firstName}`;
};
