import { React } from "react";
import { ReactComponent as SortAscending } from "../../assets/SortAscending.svg";
import { ReactComponent as SortDescending } from "../../assets/SortDecending.svg";

import './index.scss';

// sortDirection - 0 = ascending, 1= descending, null or undefined= no sorting
export const ColumnSort = ({ fieldName, sortDirection, onSortChanged }) => {

  return (
    <>
      <SortAscending
        onClick={() => onSortChanged(fieldName, 0)}
        className={`column-sort${sortDirection === 0 ? " selected" : ""}`}
      />
      <SortDescending
        onClick={() => onSortChanged(fieldName, 1)}
        className={`column-sort${sortDirection === 1 ? " selected" : ""}`}
      />
    </>
  );
};
