import React,{useEffect} from "react";
import "./PaginationView.scss";
import { Pagination } from "@mui/material";
import { Box } from "@mui/system";

const PaginationView = ({
  onChange,
  postsPerPage,
  totalPosts,
  startIndex,
  lastIndex,
  
}) => {

var text="";

if(totalPosts>0){
text = `Showing ${startIndex + 1} to ${
  lastIndex > totalPosts ? totalPosts : lastIndex
} results of ${totalPosts} records`;
}


  return (
    <Box className="box-wrapper">
      <span>{text}</span>
    
      <Pagination
        count={Math.ceil(totalPosts / postsPerPage)}
        onChange={onChange}
        siblingCount={0}
        boundaryCount={1}
      />
    </Box>
  );
};

export default PaginationView;
