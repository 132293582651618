import React, { useEffect, useState, useContext } from "react";
import AntigramHeader from "./antigramHeader/AntigramHeader.js";
import AntigramTable from "./antigramTable/AntigramTable.js";
import { geSingletWorkups } from "../../Redux/Actions/workups.js";
import { useParams } from 'react-router-dom';
import { SpinnerContext } from "../../context/spinner-context.js";
import "./index.scss";

const AntigramPanel = () => {
  const [apiResponse, setapiResponse] = useState([]);
  const [rmToggle, setRmToggle] = useState(false);
  const { changeSpinnerStatus } = useContext(SpinnerContext);

  const { panelId } = useParams();

  useEffect(() => {
    changeSpinnerStatus(true);
    getWorkupsCall();
  }, []);

  const getWorkupsCall = async () => {
    const res = await geSingletWorkups(panelId);
    if (res) {
      const responseData = res;
      setapiResponse(responseData);
    }
    changeSpinnerStatus(false);
  };

  const handleRMToggle = (val) => {
    if (val != null) {
      setRmToggle(val);
    }
  };
  useEffect(() => {}, [rmToggle]);


  const classificRules = apiResponse?.classifications?.algorithm_solution;
  const [classificRule, setClassificRule] = useState();
  useEffect(() => {
    setClassificRule(classificRules);
  },[classificRules]);
  

  const handleRuleUpdates =(val)=>{
    setClassificRule(val);
  };
  

  return (
    apiResponse && (Object.keys(apiResponse).length !== 0) && <div className="antigram-panel-root">

      <AntigramHeader
        apiAllData={apiResponse}
        rmToggle={rmToggle}
        handleRMToggle={handleRMToggle}
        classificRule={classificRule}
      />
      <AntigramTable
        apiAllData={apiResponse}
        rmToggle={rmToggle}
        handleRMToggle={handleRMToggle}
        handleRuleUpdates={handleRuleUpdates} />
    </div>
  );
};

export default AntigramPanel;
