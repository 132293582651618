import * as React from 'react';
import AlertDialogModal from "../../alertDialogModal/AlertDialogModal"
export default function AlertNote({ handleRMToggle, setMode, updateData }) {

    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const panel = () => {
        handleRMToggle(false);
        handleClose();
        setMode(false);
        updateData()
    };
    const manual = () => {
        handleRMToggle(true);
        // updateModifiedData();
        handleClose();
        setMode(false);
    }
    return (
        <AlertDialogModal
            dialogButtonName="test"
            dialogTitle="Go to Manual Analysis?"
            dialogContent="You already have a manual analysis in progress. Please make this edit directly in the manual analysis."
            btnYesText="Go to manual analysis"
            btnNoText="Cancel"
            open={open}
            // handleClickOpen={props?.handleReviewClickOpen}
            handleClose={panel}
            handleProceed={manual}
        />

    );
}