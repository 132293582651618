import React,{useState} from 'react';



export default function CreatePatient({searchFilteredResults}) {

  
  return (
    <div>
    <h2>Create Patient Form </h2>
    </div>
  );
}