import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { createWorkups } from "../../Redux/Actions/create-workups";
import {
  geSingletWorkups,
  updtSingletWorkups,
} from "../../Redux/Actions/workups";
import { useLocation, useNavigate } from "react-router-dom";
import { CrossMask } from "../../utils/icons";
import "./index.scss";
import { useParams } from "react-router-dom";
import RemoveTestModal from "./RemoveTestModal";
import { communicationStorage } from "../../services/communicationService";
import dayjs from "dayjs";

const SingleWorkup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAddToWorkupFlow, testResultData, addPatientData, editWorkupData } =
    location.state;

  console.log("isNavigatingFromWorkup", isAddToWorkupFlow, testResultData);

  // if (isAddToWorkupFlow) {
  //   communicationStorage.testResults = testResultData;
  // }

  const [flag, setFlag] = useState(editWorkupData);
  const { workupId } = useParams();
  // console.log(workupId, "redirect 2");
  const [editData, setEditData] = useState([]);

  useEffect(() => {
    if (editWorkupData) {
      getEditApiData();
    }
  }, []);

  const getEditApiData = async () => {
    const res = await geSingletWorkups(workupId);
    setEditData(res);
  };
  const { panels, workup = {} } = editData;
  const [errorClass, setErrorClass] = useState(false);
  const testDataHere = localStorage.getItem("allTestResultsData");
  const [allTestDataDetails, setAllTestDataDetails] = useState(
    JSON.parse(testDataHere)
  );

  //console.log("allTestDataDetails", allTestDataDetails);

  const patientDataHere = localStorage.getItem("allPatientData");
  const [allPatientData, setAllPatientData] = useState(
    JSON.parse(patientDataHere)
  );

  // useEffect(() => {
  //   // console.log(testResultData, allTestDataDetails)
  //   // const newAllTestResultDataDetails=[...testResultData, ...(allTestDataDetails || [])];
  //   // setAllTestDataDetails(newAllTestResultDataDetails);
  //   // localStorage.setItem("allTestResultsData", JSON.stringify(newAllTestResultDataDetails));

  //   if (isNavigatingFromWorkup) {
  //     communicationStorage.testResults=testResultData;
  //     //localStorage.setItem("testResultData", JSON.stringify(testResultData));
  //   }
  // }, [testResultData]);

  console.log(allTestDataDetails, communicationStorage.testResults);

  var storedWorkupName;
  storedWorkupName = localStorage.getItem("workupname");
  const [workupName, setWorkupName] = useState("");

  useEffect(() => {
    if (flag) {
      setWorkupName(workup?.name);
      localStorage.setItem("workupname", workup?.name);
    }
  }, [workup]);

  //needed for test results
  useEffect(() => {
    // if(flag){
    // const allTestDataDetailsData = [
    //   {
    //     status: response?.status,
    //     lab: "P",
    //   },
    // ];
    // setAllTestDataDetails(allTestDataDetailsData);
    // localStorage.setItem(
    //   "allTestResultsData",
    //   JSON.stringify(allTestDataDetailsData)
    // );
    // }
  }, []);

  useEffect(() => {
    if (flag) {
      const allPatientDataNew = [workup?.patient];
      setAllPatientData(allPatientDataNew);
      localStorage.setItem("allPatientData", JSON.stringify(allPatientDataNew));
    }
  }, [workup?.patient]);

  useEffect(() => {}, [allTestDataDetails]);
  useEffect(() => {}, [allPatientData]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setWorkupName(e.target.value);
    setErrorClass(false);
    // setFlag(!flag);
    const newData = e.target.value;
    localStorage.setItem("workupname", newData);
  };

  const handleDeleteTestItem = () => {
    localStorage.removeItem("allTestResultsData");
    window.location.reload();
  };
  const handleDeletePatientItem = () => {
    localStorage.removeItem("allPatientData");
    window.location.reload();
  };

  console.log("single workup", isAddToWorkupFlow);

  // var testDetailsToShow = [
  //   ...(allTestDataDetails || []),
  //   communicationStorage.selectedTestResult,
  // ];
var testDetailsToShow=[];
  const saveAddToWorkupFlowData = async () => {
    const workupName = localStorage.getItem("workupname");
    const backgroundTasks = [];

    testDetailsToShow.forEach(async (testResult) => {
      const apiObj = {
        name: workupName,
        //patient_guid: selectedTestResult?.patient_id,
        reference_key_map: [
          {
            antigram_reference_key: testResult?.antigram_reference_key,
            test_result_reference_key: testResult?.sample_id,
          },
        ],
      };

      const task = createWorkups(apiObj);
      backgroundTasks.push(task);
      //console.log("saved workups")
    });

    await Promise.all(backgroundTasks);
  };

  const handleCreateWorkupCall = async (e) => {
    e.preventDefault();
    let apiObj = {};
    if (
      localStorage.getItem("workupname") == null ||
      localStorage.getItem("workupname") == ""
    ) {
      setErrorClass(true);
    } else if (localStorage.getItem("workupname")) {
      if (isAddToWorkupFlow) {
        await saveAddToWorkupFlowData();
        setTimeout(() => {
          communicationStorage.isAddToWorkupFlowSuccess=true;
          console.log(communicationStorage.isAddToWorkupFlowSuccess);
          navigate("/");
        }, 1000);
      } else {
        apiObj.name = localStorage.getItem("workupname");
        if (allPatientData && allPatientData[0]?.guid != "") {
          apiObj.patient_guid = allPatientData[0].guid;
          // if (allTestDataDetails && allTestDataDetails[0]?.sample_id) {
          //   apiObj.sample_id = allTestDataDetails[0]?.sample_id;
          // console.log(allTestDataDetails[0]?.antigram_reference_key,"inosed")
          if (
            allTestDataDetails &&
            (allTestDataDetails[0]?.antigram_reference_key ||
              allTestDataDetails[0]?.test_result_reference_key)
          ) {
            let reference_key_map = [];
            const data = {
              antigram_reference_key:
                allTestDataDetails[0]?.antigram_reference_key ||
                allTestDataDetails?.antigram_reference_key,
              test_result_reference_key:
                allTestDataDetails[0]?.test_result_reference_key ||
                allTestDataDetails?.test_result_reference_key,
            };
            reference_key_map.push(data);
            apiObj.reference_key_map = reference_key_map;
          }
          // }
        }

        console.log("Going to create", apiObj);
        const apiResult = await createWorkups(apiObj);
        if (apiResult && Object.keys(apiResult).length > 0) {
          localStorage.setItem("createStatus", true);
          localStorage.removeItem("allPatientData");
          localStorage.removeItem("allTestResultsData");
          localStorage.removeItem("workupname");

          setTimeout(() => {
            navigate(`/antigram-panel/${apiResult?.response?.id}`, {
              state: { modalOpen: true },
            });
          }, 1000);
        }
      }
    }
  };
  // console.log(allTestDataDetails)
  const handleEditWorkupCall = async (e) => {
    e.preventDefault();
    let reference_key_map_data = [];
    allTestDataDetails &&
      allTestDataDetails.map((testData, idx) => {
        // console.log(testData)
        let data = [
          {
            antigram_reference_key:
              testData &&
              (testData[0]?.antigram_reference_key ||
                testData?.antigram_reference_key),
            test_result_reference_key:
              testData[0]?.test_result_reference_key ||
              testData?.test_result_reference_key,
          },
        ];
        reference_key_map_data.push(data);
      });
    let apiObj = {};

    if (
      localStorage.getItem("workupname") == null ||
      localStorage.getItem("workupname") == ""
    ) {
      setErrorClass(true);
    } else if (localStorage.getItem("workupname")) {
      apiObj.name = localStorage.getItem("workupname") || workupName;
      if (
        (allPatientData && allPatientData[0]?.guid != "") ||
        (allTestDataDetails &&
          (allTestDataDetails[0]?.antigram_reference_key ||
            allTestDataDetails[0]?.test_result_reference_key))
      ) {
        // console.log(allTestDataDetails,'ediitng',reference_key_map_data)
        apiObj.patient_guid = allPatientData && allPatientData[0]?.guid;
        // if (allTestDataDetails && allTestDataDetails[0]?.sample_id) {
        //   apiObj.sample_id = allTestDataDetails[0]?.sample_id;
        // if (
        //   allTestDataDetails &&
        //   (allTestDataDetails[0]?.antigram_reference_key ||
        //     allTestDataDetails[0]?.test_result_reference_key)
        // ) {
        apiObj.reference_key_map = reference_key_map_data;
        // }
        // }
      }
      const res = await updtSingletWorkups(workupId, apiObj);
      // console.log(res,"resupdrt")
      if ((res && res?.status == 200) || Object.keys(res).length > 0) {
        localStorage.removeItem("allPatientData");
        localStorage.removeItem("allTestResultsData");
        localStorage.removeItem("workupname");
        setTimeout(() => {
          navigate(`/antigram-panel/${res?.data?.id}`, {
            state: { modalOpen: false },
          });
        }, 2000);
      }
    }
  };

  const [open, setOpen] = useState(false);
  const [selectedTestData, setSelectedTestData] = useState(null);
  const handleClickOpen = (idVal) => {
    setSelectedTestData(idVal);
    // let  arr = allTestDataDetails.filter(function(item){
    //   return idVal.indexOf(item.id) === -1;
    // });
    // setAllTestDataDetails(arr);
    // console.log(arr,"arr")
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleProceed = () => {
    let arr = allTestDataDetails.filter(function (item) {
      return selectedTestData.indexOf(item.id) === -1;
    });
    setAllTestDataDetails(arr);
    localStorage.setItem("allTestResultsData", JSON.stringify(arr));
    setOpen(false);
    // window.location.reload();
  };

  console.log("testDetailsToShow", testDetailsToShow);

  return (
    <div className="create-workup-container">
      <RemoveTestModal
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleProceed={handleProceed}
      />
      <div className="create-workup-header">
        <span>{editWorkupData ? "Edit Workup" : "Create Workup"}</span>
        <p>
          Complete the information below to create a new workup and assign it to
          a patient
        </p>
      </div>
      <div className="workup-information">
        <b>Workup information</b>
        <label className="workup-label">
          Workup Name <span id="mandatory">*</span>
        </label>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className="workup-input"
        >
          <TextField
            placeholder="Enter Workup Name"
            id="filled-basic"
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: { paddingTop: "5px", paddingLeft: "0px" },
              },
            }}
            label=""
            variant="filled"
            value={workupName || storedWorkupName}
            onChange={(e) => handleInputChange(e)}
          />
          {errorClass && (
            <p style={{ color: "red" }}>{"Workup Name is Mandatory"}</p>
          )}
        </Box>
      </div>

      <div className="patient-information-container">
        <b>
          Patient Information
          <span className="optional"> (Optional)</span>
        </b>
        <Stack spacing={2} direction="row">
          <Link
            className="button"
            to={editWorkupData ? `/patient-home/${workupId}` : "/patient-home"}
            state={{ addPatientDetails: true }}
            variant="outlined"
          >
            {editWorkupData ? "Edit" : "Add"} patient
          </Link>
        </Stack>
        {allPatientData && allPatientData.length > 0 ? (
          allPatientData.map((patientItem, index) => {
            return (
              <div className="patient-info" key={index}>
                <div className="patient-column">
                  <label id="patient-heading">Patient Name</label>
                  <div id="data">{`${patientItem?.last_name} , ${patientItem?.first_name}`}</div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">Patient ID</label>
                  <div id="data">{patientItem?.id}</div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">Age</label>
                  <div id="data">{patientItem?.age}</div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">Date of birth</label>
                  <div id="data">
                    {patientItem?.date_of_birth &&
                      dayjs(patientItem?.date_of_birth)
                        .local()
                        .format("MM/DD/YYYY")}
                  </div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">Gender</label>
                  <div id="data">{patientItem?.gender}</div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">ABD/Rh</label>
                  <div id="data">{patientItem?.abo_rh}</div>
                </div>
                {editWorkupData && patientItem && (
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => handleDeletePatientItem}
                  >
                    <CrossMask fillColor={"#CF2F44"} width="14" height="14" />
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="patient-info">
            <div className="patient-column">
              <label id="patient-heading">Patient Name</label>
              <div id="data">{"--"}</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">Patient ID</label>
              <div id="data">{"--"}</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">Age</label>
              <div id="data">--</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">Date of birth</label>
              <div id="data">--</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">Gender</label>
              <div id="data">--</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">ABD/Rh</label>
              <div id="data">{"--"}</div>
            </div>
          </div>
        )}
      </div>
      <div className="test-info-container">
        <b>
          {editWorkupData ? "Add/Remove" : "Add"} test results
          <span className="optional"> (Optional)</span>
        </b>
        <Stack direction="row">
          <Link
            className="button"
            to={editWorkupData ? `/${workupId}` : "/"}
            state={{ addTestResults: true,
              //  isAddToWorkupFlow 
              }}
            variant="outlined"
          >
            {editWorkupData ? "Edit" : "Add"} test results
          </Link>
        </Stack>
        {allTestDataDetails && allTestDataDetails.length > 0 ? (
          allTestDataDetails?.map((testItem, index) => {
            return (
              <div className="test-info" key={index}>
                <div className="test-column">
                  <label id="test-heading">Sample ID</label>
                  <div id="data">
                    {testItem && testItem?.sample_id?.substring(0, 25)}
                  </div>
                </div>
                <div className="test-column">
                  <label id="test-heading">Lot #</label>
                  <div id="data">{testItem?.lot_number}</div>
                </div>
                <div className="test-column">
                  <label id="test-heading">Date and time</label>
                  <div id="data">
                    {testItem?.result_dt &&
                      dayjs(testItem?.result_dt)
                        .local()
                        .format("MM/DD/YYYY hh:mm ")}
                  </div>
                </div>
                <div className="test-column">
                  <label id="test-heading">Lab</label>
                  <div id="data">{testItem?.laboratory}</div>
                </div>
                <div className="test-column">
                  <label id="test-heading">Panel type</label>
                  <div id="data">{testItem?.panel_type}</div>
                </div>
                {/* <div className="test-column">
                  <label id="test-heading">Status</label>
                  <div
                    id="data"
                    className="status"
                    style={{
                      backgroundColor:
                        (testItem?.status?.toLowerCase() === "new"
                          ? "#D0E7FE"
                          : null) ||
                        (testItem?.status?.toLowerCase() === "in progress"
                          ? "#F4CF71"
                          : null) ||
                        (testItem?.status?.toLowerCase() === "in review"
                          ? " #ED8B00"
                          : null) ||
                        (testItem?.status?.toLowerCase() === "completed"
                          ? "#A4EDB0"
                          : null),
                    }}
                  >
                    {testItem?.status}
                  </div> */}
                {/* <div id="data">{testItem?.status}</div> */}
                {/* </div> */}
                {editWorkupData && allTestDataDetails.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => handleClickOpen(testItem?.id)}
                  >
                    <CrossMask fillColor={"#CF2F44"} width="14" height="14" />
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="test-info">
            <div className="test-column">
              <label id="test-heading">Sample ID</label>
              <div id="data">--</div>
            </div>
            <div className="test-column">
              <label id="test-heading">Lot #</label>
              <div id="data">--</div>
            </div>
            <div className="test-column">
              <label id="test-heading">Date and time</label>
              <div id="data">--</div>
            </div>
            <div className="test-column">
              <label id="test-heading">Lab</label>
              <div id="data">--</div>
            </div>
            <div className="test-column">
              <label id="test-heading">Panel type</label>
              <div id="data">--</div>
            </div>
            {/* <div className="test-column">
              <label id="test-heading">Status</label>
              <div
                id="data"
                className="status"
                style={{
                  backgroundColor:
                    (editWorkupData && response?.status?.toLowerCase() === "new"
                      ? "#D0E7FE"
                      : null) ||
                    (editWorkupData &&
                    response?.status?.toLowerCase() === "in progress"
                      ? "#F4CF71"
                      : null) ||
                    (editWorkupData &&
                    response?.status?.toLowerCase() === "in review"
                      ? " #ED8B00"
                      : null) ||
                    (editWorkupData &&
                    response?.status?.toLowerCase() === "completed"
                      ? "#A4EDB0"
                      : null),
                }}
              >
                {editWorkupData ? response?.status : "--"}
              </div>
            </div> */}
          </div>
        )}
      </div>

      <div className="button-container">
        <Stack direction="row">
          <Link
            className="cancel"
            variant="outlined"
            to="/workup-home"
            state={{ addTestResults: false }}
          >
            Cancel
          </Link>
        </Stack>{" "}
        <Stack direction="row">
          <Button
            className="submit"
            type="button"
            onClick={
              editWorkupData
                ? handleEditWorkupCall
                : (e) => handleCreateWorkupCall(e)
            }
            variant="contained"
          >
            {editWorkupData ? "Save" : "Create"} workup
          </Button>
        </Stack>
      </div>
    </div>
  );
};
export default SingleWorkup;
