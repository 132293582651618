import React from 'react'
import "./index.scss";

const AutoControl = () => {
  return (
    <div className='classification-tab-autocontrol'>
    <div className='label-row'>
      <p>Autocontrol result updates based on inputs in the antigram.</p>
    </div>
    <div className='values-row'>
      <b>Autocontrol result:<span>NOT TESTED</span></b>
    </div>
  </div>
  )
}
export default AutoControl;

